import { InformationButton } from "@components/buttons/information";
import {
  SHBox,
  SHStack,
  SHTable,
  SHTableBody,
  SHTableContainer,
  SHTableRow,
  SHTypography,
} from "@components/design-systems";
import { BusinessMetricTypeId, FieldTypeId } from "@models/configuration";
import {
  AnalysisDataDTO,
  AnalysisFeatureDTO,
} from "@models/platform-analysis/entities/steps/feature";
import { AnalysisDataValue } from "@models/platform-analysis/enums/dataValue";
import { ReviewStepBusinessMetricContainerDTO } from "@models/reviews/entities/steps/businessMetric";
import { useTheme } from "@mui/material";
import { renderBooleanIcon } from "@pages/platform-analysis/_id/steps/business-metric/analysis/components/feature-row";
import { renderBusinessMetricIcon } from "@pages/platform-analysis/_id/steps/business-metric/analysis/components/group-row";
import { ClickToViewPopup } from "@pages/platform-analysis/components/click-to-view-popup";
import { SummaryAnalysisCell } from "@pages/platform-analysis/_id/steps/summary/components/tables/components/summary-analysis-cell";
import { SummaryAnalysisOverallScore } from "@pages/platform-analysis/_id/steps/summary/components/tables/components/summary-analysis-overall-score";
import { toNativeValue } from "@pages/suppliers/_id/_products/_id/edit/components/tab-form/util";
import { hexToRGBA } from "@utils";
import { isEqual, isNil } from "lodash";
import { SummaryReviewHeader } from "../components/summary-review-header";

export interface BusinessReviewAppendixTableProps {
  businessReview?: ReviewStepBusinessMetricContainerDTO | null;
}
export const BusinessReviewAppendixTable = ({
  businessReview,
}: BusinessReviewAppendixTableProps) => {
  const { palette } = useTheme();

  const renderPlatformValue = (analysisData: AnalysisDataDTO) => {
    if (isEqual(analysisData?.value, AnalysisDataValue.MissingData))
      return (
        <SHTypography
          variant="body3"
          zIndex="1"
          colorVariant="third"
          textAlign="center"
        >
          No data
        </SHTypography>
      );

    switch (analysisData?.fieldTypeId) {
      case FieldTypeId.YesNo:
        return renderBooleanIcon(
          toNativeValue(analysisData?.value ?? "", FieldTypeId.YesNo),
        );
      case FieldTypeId.TextShort:
        return (
          <SHStack gap={"5px"} alignItems={"center"} direction="row">
            <SHTypography
              variant="body3"
              zIndex="1"
              colorVariant="third"
              textAlign="left"
            >
              {analysisData?.value}
            </SHTypography>
            <SHBox>
              {!isNil(analysisData?.isPositiveScore) &&
                renderBooleanIcon(analysisData?.isPositiveScore)}
            </SHBox>
          </SHStack>
        );
      case FieldTypeId.Number:
        return (
          <>
            <SHTypography
              variant="body3"
              zIndex="1"
              colorVariant="third"
              textAlign="justify"
            >
              {analysisData?.value}
            </SHTypography>
            {!isNil(analysisData?.isPositiveScore) &&
              renderBooleanIcon(analysisData?.isPositiveScore)}
          </>
        );
      case FieldTypeId.TextLong:
        return <ClickToViewPopup analysisData={analysisData} />;

      default:
        return (
          <SHTypography
            variant="body3"
            zIndex="1"
            colorVariant="third"
            textAlign="justify"
          >
            {analysisData?.value}
          </SHTypography>
        );
    }
  };

  return (
    <SHTableContainer
      sx={{
        overflow: "overlay",
        maxWidth: "max-content",
      }}
    >
      <SHTable
        style={{
          tableLayout: "auto",
          maxWidth: "max-content",
          borderCollapse: "separate",
        }}
      >
        <SummaryReviewHeader
          selectedProducts={businessReview?.selectedProducts}
        />
        <SHTableBody
          sx={{
            td: {
              borderLeft: "none",
              borderTop: "none",
            },
            "td:first-child": {
              borderLeft: `1px solid ${hexToRGBA(palette.primary[50], 0.3)}`,
            },
            ">tr:first-child td": {
              borderTop: `1px solid ${hexToRGBA(palette.primary[50], 0.3)}}`,
            },
          }}
        >
          <SummaryAnalysisOverallScore
            overallScore={businessReview?.overallScore}
          />
          {businessReview?.sections?.map((section, index) => (
            <>
              <SHTableRow key={index}>
                <SummaryAnalysisCell
                  cellColor={
                    section.businessMetricTypeId === BusinessMetricTypeId.Esg
                      ? palette.common.white
                      : palette.secondary[50]
                  }
                >
                  <SHStack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent="space-between"
                  >
                    <SHStack
                      direction={"row"}
                      alignItems={"center"}
                      gap={"5px"}
                    >
                      {renderBusinessMetricIcon(
                        section?.businessMetricTypeId as BusinessMetricTypeId,
                      )}
                      <SHTypography variant="subtitle2" paddingTop={0}>
                        {`${section?.businessMetricTypeName}
                        ${section?.totalSelectedFeature}/${section?.totalFeature}`}
                      </SHTypography>
                    </SHStack>
                    {section?.description && (
                      <InformationButton content={section?.description} />
                    )}
                  </SHStack>
                </SummaryAnalysisCell>
                {Array.from(
                  Array(businessReview?.selectedProducts?.length).keys(),
                ).map((index) => (
                  <SummaryAnalysisCell
                    key={index}
                    cellColor={
                      section.businessMetricTypeId === BusinessMetricTypeId.Esg
                        ? palette.common.white
                        : palette.secondary[50]
                    }
                  ></SummaryAnalysisCell>
                ))}
              </SHTableRow>
              {section?.features?.map((feature: AnalysisFeatureDTO) => (
                <SHTableRow key={feature.id}>
                  <SummaryAnalysisCell
                    cellColor={
                      section.businessMetricTypeId === BusinessMetricTypeId.Esg
                        ? palette.common.white
                        : palette.secondary[50]
                    }
                  >
                    <SHStack
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent="space-between"
                    >
                      <SHStack
                        direction={"row"}
                        alignItems={"center"}
                        gap={"5px"}
                      >
                        <SHTypography
                          variant="body1"
                          sx={{
                            color: palette.text.third,
                          }}
                        >
                          {feature?.name}
                        </SHTypography>
                      </SHStack>
                    </SHStack>
                  </SummaryAnalysisCell>
                  {feature?.analysisData?.map((product, index) => (
                    <SummaryAnalysisCell
                      cellColor={
                        section.businessMetricTypeId ===
                        BusinessMetricTypeId.Esg
                          ? palette.common.white
                          : palette.secondary[50]
                      }
                      key={index}
                    >
                      <SHStack
                        gap={"5px"}
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent="center"
                      >
                        {renderPlatformValue(product)}
                      </SHStack>
                    </SummaryAnalysisCell>
                  ))}
                </SHTableRow>
              ))}
            </>
          ))}
        </SHTableBody>
      </SHTable>
    </SHTableContainer>
  );
};
