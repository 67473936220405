import { SHStack } from "@components/design-systems";
import SHSkeleton from "@components/design-systems/sh-skeleton";
import React from "react";

export const ViewStandardSkeleton = () => (
  <SHStack display={"grid"} gridTemplateColumns={"6.6fr 3.25fr"} gap={"15px"}>
    <SHStack spacing={3}>
      <SHStack spacing={1}>
        <SHSkeleton height={30} width={"40%"} delay={false} />
        <SHSkeleton height={20} width={"60%"} delay={false} />
        <SHSkeleton height={20} width={"100%"} delay={false} />
      </SHStack>
      <SHStack spacing={1}>
        <SHSkeleton height={30} width={"40%"} delay={false} />
        <SHSkeleton height={20} width={"60%"} delay={false} />
        <SHSkeleton height={20} width={"100%"} delay={false} />
      </SHStack>
    </SHStack>
    <SHStack spacing={3}>
      <SHStack spacing={1}>
        <SHSkeleton height={30} width={"100%"} delay={false} />
        <SHSkeleton height={20} width={"60%"} delay={false} />
        <SHSkeleton height={20} width={"60%"} delay={false} />
      </SHStack>
      <SHStack spacing={1}>
        <SHSkeleton height={30} width={"100%"} delay={false} />
        <SHSkeleton height={20} width={"60%"} delay={false} />
        <SHSkeleton height={20} width={"60%"} delay={false} />
      </SHStack>
    </SHStack>
  </SHStack>
);
