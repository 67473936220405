import { CloseButton } from "@components/buttons/close";
import {
  SHBox,
  SHPopper,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { TooltipAllowSVG } from "@components/svgs";
import { Link, useTheme } from "@mui/material";
import { hexToRGBA } from "@utils";
import { isEqual } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useClickAway, useToggle, useWindowSize } from "react-use";

export const UserInformationCardRegions = (params: { regions?: string[] }) => {
  const { regions } = params || {};
  const { palette } = useTheme();
  const windowSize = useWindowSize();

  const [isOpenTooltip, openTooltip] = useToggle(false);
  const [isClamped, toggleClamped] = useToggle(false);
  const [isFlipped, toggleFlipped] = useToggle(false);
  const [anchorEl, setAnchorEl] = useState<HTMLLabelElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const anchorRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useClickAway(anchorRef, () => {
    openTooltip(false);
  });

  const regionDisplayName = useMemo(() => {
    return regions?.join(", ");
  }, [regions]);

  const handleOnShowMore = (event: any) => {
    setAnchorEl(event.currentTarget);
    openTooltip();
  };

  useEffect(() => {
    const scrollHeight = contentRef.current?.scrollHeight ?? 0;
    const clientHeight = contentRef.current?.clientHeight ?? 0;

    toggleClamped(scrollHeight > clientHeight);
    // eslint-disable-next-line
  }, [contentRef.current, windowSize]);

  return (
    <SHStack direction="row" width="100%">
      <SHStack width={isClamped ? "75%" : "95%"}>
        <SHTypography variant="body2" zIndex="1" colorVariant="third">
          <SHStack
            ref={contentRef}
            sx={{
              lineBreak: "anywhere",
              WebkitLineBreak: "anywhere",
              overflow: "hidden",
              display: "-webkit-box",
              lineClamp: 1,
              WebkitLineClamp: 1,
              WebkitBoxOrient: "vertical",
            }}
          >
            {regionDisplayName}
          </SHStack>
        </SHTypography>
      </SHStack>

      {isClamped && (
        <SHStack width="20%">
          <SHStack ref={anchorRef}>
            <Link
              variant="body1"
              component="label"
              underline="none"
              sx={{
                paddingLeft: 1,
                fontWeight: 600,
                cursor: "pointer",
                color: palette.text.secondary,
                marginLeft: "auto",
                whiteSpace: "nowrap",
              }}
              onClick={handleOnShowMore}
            >
              Show all
            </Link>
          </SHStack>

          <SHPopper
            anchorEl={anchorEl}
            open={isOpenTooltip}
            placement="top-start"
            popperOptions={{
              modifiers: [
                {
                  name: "updateLogger",
                  enabled: true,
                  phase: "afterWrite",
                  fn: ({ state }: any) => {
                    toggleFlipped(isEqual(state?.placement, "top-end"));
                  },
                },
              ],
            }}
          >
            <SHBox pb={1}>
              <SHStack
                ref={wrapperRef}
                sx={{
                  p: 1,
                  pr: 4,
                  width: "350px",
                  minHeight: "60px",
                  bgcolor: hexToRGBA(palette.common.white, 0.85),
                  border: "1px solid #E3E3E3",
                  backdropFilter: "blur(2px)",
                  borderRadius: "2px",
                  position: "relative",
                }}
              >
                <SHTypography
                  variant="body2"
                  zIndex="1"
                  colorVariant="third"
                  sx={{
                    textAlign: "left",
                  }}
                >
                  {regionDisplayName}
                </SHTypography>
                <SHBox
                  sx={{ position: "absolute", top: "10px", right: "10px" }}
                >
                  <CloseButton onClick={handleOnShowMore} />
                </SHBox>
                <SHBox
                  sx={{
                    position: "absolute",
                    bottom: "-11.7px",
                    left: isFlipped ? "330px" : 0,
                  }}
                >
                  <TooltipAllowSVG />
                </SHBox>
              </SHStack>
            </SHBox>
          </SHPopper>
        </SHStack>
      )}
    </SHStack>
  );
};
