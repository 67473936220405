import {
  SHDivider,
  SHDotGroupProps,
  SHUploadImage,
} from "@components/design-systems";
import { GridImageGallery } from "@components/grid-image-gallery";
import { ImageSVG } from "@components/svgs";
import { useNotification } from "@hooks/useNotification";
import { FieldGroup } from "@layouts/form/field-group";
import { S3ObjectDTO } from "@models/core";
import {
  deleteAvatar,
  uploadAvatar,
} from "@services/file-storage/fileStorageService";
import { renameFile } from "@utils/data-type/file";
import { isEmpty } from "lodash";
import { useState } from "react";
import { FileRejection } from "react-dropzone";

export interface GalleryFieldProps {
  title?: string;
  galleryBoxTitle?: string;
  subTitle?: string;
  subTitleColor?: string;
  value: S3ObjectDTO[];
  onChange: (value: S3ObjectDTO[]) => void;
  disabled?: boolean;
  dotGroupProps?: Omit<SHDotGroupProps, "disabled">;
}
export const GalleryField = ({
  title,
  subTitle,
  subTitleColor,
  galleryBoxTitle,
  value,
  onChange,
  disabled,
  dotGroupProps,
}: GalleryFieldProps) => {
  const { notify } = useNotification();
  const [isUploading, setIsUploading] = useState(false);
  const [uploadMessage, setUploadMessage] = useState<string | null>(null);

  const handleOnUpload = async (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
  ) => {
    setUploadMessage(
      !isEmpty(fileRejections)
        ? "Unsupported file format. Please upload another file."
        : null,
    );
    if (isEmpty(acceptedFiles)) return;

    setIsUploading(true);
    let newValues: S3ObjectDTO[] = [];
    let apis: any[] = [];
    acceptedFiles.forEach((file) => {
      const form = new FormData();
      form.append("file", renameFile(file));
      apis.push(uploadAvatar(form));
    });
    const responses = await Promise.all(apis);
    responses.forEach(({ data, message }) => {
      if (data) {
        newValues.push({
          key: data?.key,
          url: data?.url,
        });
      } else {
        notify(message, {
          variant: "error",
          close: true,
        });
      }
    });
    setIsUploading(false);
    onChange([...value, ...newValues]);
  };

  const handleOnDelete = async (s3Key: string) => {
    if (!s3Key) return;
    const { isSuccess, message } = await deleteAvatar(s3Key);
    if (isSuccess) {
      onChange(value.filter((img) => img.key !== s3Key));
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
  };

  return (
    <>
      <SHDivider />
      <FieldGroup
        dotGroupProps={dotGroupProps}
        icon={<ImageSVG />}
        title={title}
        subTitle={subTitle}
        subTitleColor={subTitleColor}
      >
        <SHUploadImage
          buttonText="Click to add"
          objectFit="contain"
          isUploading={isUploading}
          src={""}
          isCircular={false}
          error={!!uploadMessage}
          helperText={uploadMessage}
          dropzoneOptions={{
            multiple: true,
            accept: {
              "image/*": [".svg", ".png", ".jpg", ".gif"],
            },
            onDrop: handleOnUpload,
            disabled: isUploading || disabled,
          }}
          showImage={false}
        />
      </FieldGroup>
      <GridImageGallery
        previewImagesDialogTitle={galleryBoxTitle}
        images={value}
        handleOnDelete={handleOnDelete}
      />
    </>
  );
};
