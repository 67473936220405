import { SHBox } from "@components/design-systems";
import { SHIconLoading } from "@components/design-systems/sh-icon-loading";
import { CloseSVG, SearchSVG } from "@components/svgs";
import {
  IconButton,
  OutlinedInput,
  OutlinedInputProps,
  useTheme,
} from "@mui/material";
import { isEqual } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { SearchDebounceTime } from "./constant";

export interface SHSearchBoxProps extends Omit<OutlinedInputProps, "onChange"> {
  onChange?: (value: string | null) => void;
  value?: string | null;
  isLoading?: boolean;
  width?: string | number;
  debouceTime?: number;
}
export const SHSearchBox = ({
  onChange,
  isLoading,
  value = "",
  width = "309px",
  debouceTime = SearchDebounceTime,
  ...others
}: SHSearchBoxProps): JSX.Element => {
  const { palette } = useTheme();
  const timer = useRef<NodeJS.Timeout | null>(null);
  const [searchText, setSearchText] = useState<string | null>(value);
  const isEmpty = useMemo(
    () => !searchText || searchText.trim() === "",
    [searchText],
  );
  const handleOnSearchChange = useCallback(
    (value: string | null) => {
      if (onChange) onChange(value);
    },
    [onChange],
  );

  const clearTimer = () => {
    if (timer.current) clearTimeout(timer.current);
  };

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const newValue = event.target.value;
      setSearchText(newValue);
      if (newValue.length > 0 && newValue.trim() === "") return;
      clearTimer();
      timer.current = setTimeout(() => {
        handleOnSearchChange(newValue);
      }, debouceTime);
    },
    [handleOnSearchChange, debouceTime],
  );

  const handleOnKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (event.key === "Enter") {
        clearTimer();
        handleOnSearchChange(searchText);
      }
    },
    [searchText, handleOnSearchChange],
  );

  useEffect(() => {
    return () => {
      clearTimer();
    };
  }, []);

  useEffect(() => {
    if (!isEqual(value, searchText)) {
      setSearchText(value);
    }
    // eslint-disable-next-line
  }, [value]);

  return (
    <SHBox
      sx={{ position: "relative", width: width }}
      data-testid="sh-search-box"
    >
      <OutlinedInput
        value={searchText ?? ""}
        onChange={handleOnChange}
        inputProps={{
          onKeyDown: handleOnKeyDown,
        }}
        size="small"
        sx={{
          borderRadius: "40px",
          height: "38px",
          width: "100%",
          fontSize: "14px",
          backgroundColor: palette.background.default,
          "&>.MuiInputBase-input": {
            paddingRight: "38px",
          },
          "&>.MuiInputBase-input::placeholder": {
            color: palette.text.disabled,
            fontSize: "14px",
            fontWeight: 400,
          },
          "&>.MuiInputBase-input::-ms-input-placeholder": {
            color: palette.text.disabled,
            fontSize: "14px",
            fontWeight: 400,
          },
          "&>.MuiOutlinedInput-notchedOutline": {
            borderColor: palette.text.primary,
          },
        }}
        {...others}
      />
      <SHBox
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          width: "42px",
          height: "38px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {isLoading ? (
          <SHIconLoading />
        ) : isEmpty ? (
          <SearchSVG data-testid="search-icon" />
        ) : (
          <IconButton
            data-testid="clear-button"
            color="error"
            size="small"
            onClick={() => {
              setSearchText(null);
              handleOnSearchChange(null);
            }}
          >
            <CloseSVG color={palette.primary.main} width={10} height={10} />
          </IconButton>
        )}
      </SHBox>
    </SHBox>
  );
};
