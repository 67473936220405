import { RefObject, useEffect, useRef } from "react";

export const useClickAwayRefs = <E extends Event = Event>(
  ref: RefObject<HTMLElement | null> | Array<RefObject<HTMLElement | null>>,
  onClickAway: (event: E) => void,
  events: string[] = ["mousedown", "touchstart"],
) => {
  const savedCallback = useRef(onClickAway);
  const savedRefs = useRef<Array<RefObject<HTMLElement | null>>>(
    Array.isArray(ref) ? ref : [ref],
  );

  useEffect(() => {
    savedCallback.current = onClickAway;
    savedRefs.current = Array.isArray(ref) ? ref : [ref];
  }, [onClickAway, ref]);

  useEffect(() => {
    const handler = (event: E) => {
      const clickedOutside = savedRefs.current.every((ref) => {
        const { current: el } = ref;
        return el && !el.contains(event.target as Node);
      });

      if (clickedOutside) {
        savedCallback.current(event);
      }
    };

    for (const eventName of events) {
      document.addEventListener(eventName, (e) => handler(e as E));
    }

    return () => {
      for (const eventName of events) {
        document.removeEventListener(eventName, (e) => handler(e as E));
      }
    };
  }, [events]);
};
