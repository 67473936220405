import { SHContainer, SHStack, SHTabs } from "@components/design-systems";
import AccessDeniedDialog from "@components/dialogs/access-denied";
import { PageRoutes } from "@constants/routes";
import { useAppParams } from "@hooks/useAppParams";
import { useIsNew } from "@hooks/useIsNew";
import { useNotification } from "@hooks/useNotification";
import { useUserPermissions } from "@hooks/userUserPermission";
import { TopBarPlacement } from "@layouts/top-bar";
import { RootState } from "@redux/store";
import { updateSupplier } from "@redux/slices/supplier";
import { getSupplier } from "@services/suppliers";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate, useParams } from "react-router";
import { useEffectOnce } from "react-use";
import ManageSupplierSkeleton from "./skeleton";

export const ManageSupplier = () => {
  const isNew = useIsNew();
  const {
    isSupplierGroup,
    canViewSupplierOverview,
    canViewSupplierTeam,
    canViewSupplierBilling,
    canViewSupplierProductList,
    canViewSupplierInvoices,
  } = useUserPermissions();
  const { supplierData } = useSelector((state: RootState) => state.supplier);
  const { supplierId } = useParams<{ supplierId: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { notify } = useNotification();
  const [isLoadingSupplier, setIsLoadingSupplier] = useState(false);
  const { isMySupplier } = useAppParams();
  const accessDenied = isSupplierGroup && !isMySupplier;

  useEffect(() => {
    if (!isNew && supplierId && !accessDenied) {
      loadSupplier(supplierId);
    }
    // eslint-disable-next-line
  }, [isNew, supplierId, accessDenied]);

  useEffectOnce(() => {
    return () => {
      dispatch(updateSupplier());
    };
  });

  const loadSupplier = async (supplierId: string) => {
    setIsLoadingSupplier(true);
    const { data, message } = await getSupplier(supplierId);
    setIsLoadingSupplier(false);

    if (data) {
      dispatch(updateSupplier(data));
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
      navigate(`${PageRoutes.suppliers}`);
    }
  };
  if (accessDenied) return <AccessDeniedDialog />;
  if (isLoadingSupplier) {
    return <ManageSupplierSkeleton />;
  }
  if (!supplierData && !isNew) return null;
  return (
    <SHContainer sx={{ px: { xs: "16px", lg: 0 } }}>
      <SHStack sx={{ paddingY: 3 }} spacing={3}>
        <TopBarPlacement />
        <SHTabs
          isNavLink
          tabs={[
            {
              label: "Overview",
              linkTo: "",
              hidden: !canViewSupplierOverview,
            },
            {
              label: "Team",
              hidden: isNew || !canViewSupplierTeam,
              linkTo: "team",
            },
            {
              label: "Products",
              hidden: isNew || !canViewSupplierProductList,
              linkTo: "products",
            },
            {
              label: "Subscription",
              hidden: isNew || !canViewSupplierBilling,
              linkTo: "subscription",
            },
            {
              label: "Invoices",
              hidden: isNew || !canViewSupplierInvoices,
              linkTo: "invoices",
            },
          ]}
          isLoading={isLoadingSupplier}
        />
        <Outlet />
      </SHStack>
    </SHContainer>
  );
};
