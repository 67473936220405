import {
  SHBox,
  SHButton,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { DownloadSVG } from "@components/svgs";
import { FeesDisplayStyle } from "@models/platform-analysis/enums/fee/displayStyle";
import {
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  useTheme,
} from "@mui/material";
import { utils, writeFile } from "xlsx-js-style";
import { getWorkSheet } from "./utils";
import { exportExcelSmaList } from "@services/investment/investmentService";
import { useNotification } from "@hooks/useNotification";
import { SMAListExcelExcelRequestDTO } from "@models/managed-accounts/entities/smaList";
import { DELAY_TIME } from "@constants";
import { hexToRGBA } from "@utils";
import shadows from "@mui/material/styles/shadows";
import { MaxSelectedInvestmentForExcelDownload } from "@pages/managed-accounts/sma-list/config";
import { useEffect, useState } from "react";
import { format } from "date-fns";

interface ExportMAListExcelButtonProps {
  smaListExcelDtoPayload?: SMAListExcelExcelRequestDTO;
  mode?: FeesDisplayStyle;
  disabled?: boolean;
}

export const ExportMAListExcelButton = ({
  mode = FeesDisplayStyle.Percentage,
  smaListExcelDtoPayload,
}: ExportMAListExcelButtonProps) => {
  const { palette } = useTheme();
  const { notify } = useNotification();

  const [isDisable, setIsDisable] = useState(false);

  const TooltipStyled = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "transparent",
      paddingRight: "0px",
    },
    [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
      {
        marginTop: "-1px",
      },
  }));

  useEffect(() => {
    if (smaListExcelDtoPayload) {
      setIsDisable(
        smaListExcelDtoPayload.selectedInvestments.length === 0 ||
          smaListExcelDtoPayload.selectedInvestments.length >
            MaxSelectedInvestmentForExcelDownload,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smaListExcelDtoPayload?.selectedInvestments.length]);

  const handleOnDownload = async () => {
    if (smaListExcelDtoPayload) {
      const { data, isSuccess, message } = await exportExcelSmaList(
        smaListExcelDtoPayload,
      );

      if (!isSuccess || !data) {
        notify(message, {
          variant: "error",
          close: true,
        });

        return;
      }

      const workbook = utils.book_new();
      const worksheet = getWorkSheet(data, mode);
      // Add the worksheet to the workbook
      utils.book_append_sheet(workbook, worksheet, "Analysis");
      // Save the workbook
      writeFile(
        workbook,
        `SuitabilityHub SMA List Export - Fee Analysis ${format(
          new Date(),
          "yyyyMMdd",
        )}.xlsx`,
      );
    }
  };

  const renderTooltipTitle = () => {
    return smaListExcelDtoPayload && isDisable ? (
      <SHStack
        sx={{
          p: 1,
          mt: "3px",
          maxWidth: "1000px",
          minHeight: "20px",
          bgcolor: hexToRGBA(palette.common.white, 0.85),
          border: "1px solid #E3E3E3",
          backdropFilter: "blur(2px)",
          borderRadius: "3px",
          boxShadow: shadows[1],
          position: "relative",
        }}
      >
        {smaListExcelDtoPayload?.selectedInvestments.length > 0 && (
          <SHTypography variant="body3">
            Download table for selected investments. Fewer than{" "}
            {MaxSelectedInvestmentForExcelDownload} options must be selected.
          </SHTypography>
        )}
        {smaListExcelDtoPayload?.selectedInvestments.length === 0 && (
          <SHTypography variant="body3">
            Download table for selected investments. 1-30 investments must be
            selected.{" "}
          </SHTypography>
        )}
      </SHStack>
    ) : (
      <></>
    );
  };

  return (
    <SHStack direction="row" spacing={1} alignItems="center">
      {smaListExcelDtoPayload &&
      smaListExcelDtoPayload.selectedInvestments.length > 0 ? (
        <SHTypography variant="body1" colorVariant="third" lineHeight={1.2}>
          {smaListExcelDtoPayload.selectedInvestments.length} selected
        </SHTypography>
      ) : (
        <></>
      )}
      <TooltipStyled
        placement="bottom"
        enterDelay={DELAY_TIME}
        enterNextDelay={DELAY_TIME}
        title={renderTooltipTitle()}
      >
        <SHBox component={"span"}>
          <SHButton
            onClick={handleOnDownload}
            startIcon={
              <DownloadSVG width={12} height={13} color={palette.text.white} />
            }
            variant="contained"
            disableRipple
            disabled={isDisable}
            sx={{
              "&:active svg path": {
                fill: palette.common.white,
              },
            }}
          >
            Excel
          </SHButton>
        </SHBox>
      </TooltipStyled>
    </SHStack>
  );
};
