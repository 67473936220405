import { SHFilterOption } from "@components/design-systems";
import { SupplierStatus } from "@models/supplier/enums/status";

export const StatusSuppliersFilterOptions: SHFilterOption[] = [
  {
    label: "Active",
    value: SupplierStatus.Active,
  },
  {
    label: "Disabled",
    value: SupplierStatus.Disabled,
  },
  {
    label: "Archived",
    value: SupplierStatus.Archived,
  },
];
export const DefaultSuppliersStatusFilterValue = [
  SupplierStatus.Active,
  SupplierStatus.Disabled,
];

export enum AdditionalVisualizeTable {
  INVESTMENT_APPROACH = "Investment approach",
  ASSET_ALLOCATION_NOTES = "Asset allocation notes",
  ASSET_ALLOCATION = "Asset allocation",
}

export enum InvestmentFeatureHiddenInViewMode {
  TARGET_EXISTS = "Target Exists"
}

export const SubColumnNames = ["Net capital gains (% p.a.)", "Income (% p.a.)"];

export const SpecialSection = [
  "Service offering",
  "Reporting and communications",
];
