import { Color, PaletteOptions } from "@mui/material";

declare module "@mui/material/styles/createPalette" {
  interface TypeText {
    darker: string;
    third: string;
    fourth: string;
    white: string;
  }
  interface PaletteColor extends Color {}
}

export const lightPalette: PaletteOptions = {
  primary: {
    light: "#4942db",
    main: "#332CC2",
    dark: "#2E28AF",
    "50": "#adabe7",
    "800": "#282297",
    "900": "#1E1970",
  },
  secondary: {
    light: "#2a8ff7",
    main: "#007DFF",
    dark: "#086dd4",
    "50": "#F8F8FD",
    "100": "#D6DDEB",
    "200": "#C5DDFD",
  },
  info: {
    light: "#6de8df",
    main: "#4ecdc4",
    dark: "#1b968e",
  },
  success: {
    light: "#0EC48F",
    main: "#07A274",
    dark: "#219653",
    "50": "#CFF3E9",
    "100": "#6FDCBC",
    "200": "#007F5B",
  },
  warning: {
    light: "#FDD890",
    main: "#FF753C",
    dark: "#d65824",
    "50": "#EDA114",
    "800": "#F3612D",
  },
  error: {
    light: "#F56D5F",
    main: "#FC5746",
    dark: "#D15244",
    "50": "#FDEEEE",
    "100": "#FCDBD8",
    "200": "#EB5757",
    "300": "#F56C5E",
    "400": "#F44B42",
    "500": "#D32A2A",
  },
  text: {
    darker: "#0A085C",
    primary: "#25324B",
    secondary: "#007DFF",
    disabled: "#7C8493",
    third: "#515B6F",
    fourth: "#2C3950",
    white: "#ffffff",
  },
  common: {
    black: "#25324B",
    white: "#ffffff",
  },
};
