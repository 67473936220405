import { SHContainer, SHStack, SHTabs } from "@components/design-systems";
import AccessDeniedDialog from "@components/dialogs/access-denied";
import { SHTopBarSkeleton } from "@components/skeletons/layouts";
import { useAppParams } from "@hooks/useAppParams";
import { useIsNew } from "@hooks/useIsNew";
import { usePractice } from "@hooks/usePractice";
import { usePracticeBillingInfo } from "@hooks/usePracticeBillingInfo";
import { useUserPermissions } from "@hooks/userUserPermission";
import { TopBarPlacement } from "@layouts/top-bar";
import { AdviserFirmDTO } from "@models/practice/entities/practice";
import { useEffect } from "react";
import { Outlet } from "react-router";

export const ManagePractice = () => {
  const isNew = useIsNew();
  const {
    practiceId,
    setPractice,
    loadPractice,
    loadOrangeDotBillingInfo,
    ui: {
      isLoading,
      billingInfo: { showRedDot },
    },
  } = usePractice();
  const { loadClientSecret, loadPaymentMethod } = usePracticeBillingInfo();
  const { isMyAdviserFirm } = useAppParams();
  const {
    isAdminGroup,
    isAdviserAdminGroup,
    isAdviserTrialGroup,
    isAdviserGroup,
  } = useUserPermissions();
  const canAccess =
    isAdminGroup ||
    (isMyAdviserFirm && (isAdviserAdminGroup || isAdviserTrialGroup));

  useEffect(() => {
    if (!canAccess) return;
    if (isNew || !practiceId) {
      setPractice({ ...new AdviserFirmDTO() });
      return;
    }
    loadPractice(practiceId);
    if (isAdviserAdminGroup) {
      loadClientSecret(practiceId);
      loadPaymentMethod(practiceId);
    }
    if (isAdviserGroup) {
      loadOrangeDotBillingInfo(practiceId);
    }
    // eslint-disable-next-line
  }, [practiceId]);

  if (!canAccess) return <AccessDeniedDialog />;

  return (
    <SHContainer sx={{ px: { xs: "16px", lg: 0 } }}>
      <SHStack sx={{ paddingY: 3 }} spacing={3}>
        {isLoading && (
          <SHTopBarSkeleton totalButtons={2} marginY={0} marginTop={3} />
        )}
        <TopBarPlacement sx={{ display: isLoading ? "none" : undefined }} />
        <SHTabs
          isNavLink
          isLoading={isLoading}
          tabs={[
            {
              label: "Overview",
              linkTo: "",
            },
            {
              label: "Team",
              hidden: isNew,
              linkTo: "team",
            },
            {
              label: "Subscription",
              hidden: isNew,
              linkTo: "subscription",
            },
            {
              label: "Billing info",
              hidden: isNew || !isAdviserAdminGroup,
              linkTo: "billingInfo",
              dotGroupProps: {
                redDot: showRedDot,
              },
            },
            {
              label: "Invoices",
              hidden: isNew || !isAdviserAdminGroup,
              linkTo: "invoices",
            },
          ]}
        />
        <Outlet />
      </SHStack>
    </SHContainer>
  );
};
