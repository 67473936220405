import { DeleteButton } from "@components/buttons/delete";
import {
  SHBox,
  SHImage,
  SHStack,
  SHStackProps,
} from "@components/design-systems";
import { ZoomSVG } from "@components/svgs/zoom";
import { S3ObjectDTO } from "@models/core";
import { useTheme } from "@mui/material";
import { hexToRGBA } from "@utils";

export interface ImageItemProps extends SHStackProps {
  image: S3ObjectDTO;
  handleOnDelete?: (imageId: string) => void;
  handleOnZoomOut: (imageId?: string) => void;
}

export const ImageItem = ({
  image,
  handleOnDelete,
  handleOnZoomOut,
  ...others
}: ImageItemProps) => {
  const { palette } = useTheme();

  return (
    <SHStack
      width={"100%"}
      height={"100%"}
      position={"relative"}
      sx={{
        gap: "16px",
        padding: "5px",
        boxSizing: "border-box",
        background: `${palette.common.white}`,
        border: `1px solid ${palette.text.disabled}`,
        backdropFilter: "blur(0.5px)",
        cursor: "pointer",
        boxShadow: `3px 3px 4px ${hexToRGBA(palette.common.black, 0.25)}`,
        transition: "all .1s linear",
        "&:hover": {
          border: `1px solid ${palette.primary[800]}`,
          boxShadow: "none",
          "& .sh-image-card-zoom-button": {
            opacity: 1,
          },
          "& .sh-image-card-image": {
            filter: "brightness(.8)",
          },
          background: `linear-gradient(0deg, ${hexToRGBA(
            palette.secondary[100],
            0.4,
          )}, ${hexToRGBA(palette.secondary[100], 0.4)}),${
            palette.common.white
          }`,
        },
        "&:active": {
          boxShadow: `inset 3px 3px 4px ${hexToRGBA(
            palette.common.black,
            0.25,
          )}`,
        },
      }}
      {...others}
    >
      {typeof handleOnDelete === "function" && (
        <DeleteButton
          style={{
            position: "absolute",
            top: 5,
            right: 5,
            zIndex: 2,
          }}
          onClick={() => {
            handleOnDelete(image?.key);
          }}
        />
      )}
      <SHImage
        className="sh-image-card-image"
        src={image?.url}
        alt={image?.description}
        loading="lazy"
        height={"100%"}
        width={"100%"}
        style={{
          transition: "all 0.1s ease-in-out",
          overflowClipMargin: "unset",
        }}
      />
      <SHBox
        className={"sh-image-card-zoom-button"}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          opacity: 0,
          cursor: "pointer",
          transition: "all 0.2s ease-in-out",
        }}
        onClick={(e) => {
          handleOnZoomOut(image?.key);
        }}
      >
        <ZoomSVG />
      </SHBox>
    </SHStack>
  );
};
