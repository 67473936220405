import { CloseButton } from "@components/buttons/close";
import {
  SHBox,
  SHHtmlBlock,
  SHPopper,
  SHStack,
} from "@components/design-systems";
import { TooltipAllowSVG } from "@components/svgs";
import { useClickAwayRefs } from "@hooks/useClickAwayRefs";
import { AnalysisDataDTO } from "@models/platform-analysis/entities/steps/feature";
import { Link, useTheme } from "@mui/material";
import { hexToRGBA } from "@utils";
import { isNil } from "lodash";
import { useRef, useState } from "react";
import { renderBooleanIcon } from "../../_id/steps/business-metric/analysis/components/feature-row";

type ClickToViewPopupProps = {
  label?: string;
  analysisData: AnalysisDataDTO;
};

export const ClickToViewPopup = ({
  label = "Click to view",
  analysisData,
}: ClickToViewPopupProps) => {
  const { palette } = useTheme();
  const labelRef = useRef<HTMLLabelElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLLabelElement | null>(null);
  const [open, setOpen] = useState(false);
  const [currLongTextValue, setCurrLongTextValue] = useState("");

  const handleOnShowMore = (event: any, value?: string) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
    setCurrLongTextValue(value ?? "");
  };

  useClickAwayRefs([labelRef, wrapperRef], () => {
    setOpen(false);
  });

  return (
    <SHStack
      gap={"5px"}
      direction={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      <Link
        ref={labelRef}
        variant="body1"
        component="label"
        underline="none"
        sx={{
          paddingLeft: "5px",
          fontWeight: 600,
          cursor: "pointer",
          color: palette.text.secondary,
          lineHeight: "120%",
        }}
        onClick={(event: any) => handleOnShowMore(event, analysisData?.value)}
      >
        {label}
      </Link>
      {!isNil(analysisData?.isPositiveScore) &&
        renderBooleanIcon(analysisData?.isPositiveScore)}
      <SHPopper
        anchorEl={anchorEl}
        open={open}
        placement="top-start"
        popperOptions={{
          modifiers: [{ name: "flip", enabled: false }],
        }}
        sx={{
          zIndex: 1100,
        }}
      >
        <SHStack
          ref={wrapperRef}
          sx={{
            p: "20px 20px 15px 20px",
            maxWidth: "577px",
            maxHeight: "478px",
            bgcolor: hexToRGBA(palette.common.white, 0.85),
            border: "1px solid #E3E3E3",
            backdropFilter: "blur(2px)",
            borderRadius: "2px",
            position: "relative",
            bottom: "5px",
          }}
        >
          <SHHtmlBlock
            variant="body2"
            content={currLongTextValue}
            color={palette.text.third}
            textAlign="left"
            sx={{
              pr: "5px",
              zIndex: 1,
              overflow: "auto",
              "::-webkit-scrollbar-thumb": {
                boxShadow: "none",
              },
            }}
          />
          <SHBox sx={{ position: "absolute", top: "7px", right: "12px" }}>
            <CloseButton onClick={handleOnShowMore} />
          </SHBox>
          <SHBox sx={{ position: "absolute", bottom: "-11.7px", left: 0 }}>
            <TooltipAllowSVG />
          </SHBox>
        </SHStack>
      </SHPopper>
    </SHStack>
  );
};
