import {
  SHDotGroup,
  SHDotGroupProps,
  SHHtmlBlock,
} from "@components/design-systems";
import { SHStack, SHStackProps } from "@components/design-systems/sh-stack";
import { useTheme } from "@mui/material";

export interface FieldGroupProps {
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  subTitleColor?: string;
  icon?: React.ReactNode;
  postfixLabel?: React.ReactNode;
  required?: boolean;
  dotGroupProps?: Omit<SHDotGroupProps, "disabled">;
  children?: React.ReactNode;
  firstLine?: boolean;
  maxWidth?: number | string;
}
export const FieldGroup = ({
  title,
  subTitle,
  subTitleColor,
  icon,
  postfixLabel,
  required,
  children,
  firstLine,
  maxWidth,
  dotGroupProps,
  ...others
}: FieldGroupProps & SHStackProps) => {
  const { palette } = useTheme();
  return (
    <SHStack
      spacing={3}
      sx={{ paddingY: 3, paddingTop: firstLine ? 0 : 3 }}
      alignItems={"stretch"}
      justifyContent={"space-between"}
      direction={{ xs: "column", md: "row" }}
      {...others}
    >
      <SHStack sx={{ maxWidth: firstLine ? undefined : maxWidth ?? "260px" }}>
        <SHStack direction="row" spacing={1} alignItems="center">
          {icon}
          <SHStack direction="row" spacing={0.5} alignItems="start">
            {typeof title === "string" ? (
              <SHHtmlBlock
                variant={firstLine ? "subtitle1" : "subtitle2"}
                textAlign={"left"}
                content={title}
              />
            ) : (
              title
            )}
            <SHDotGroup redDot={required} paddingTop={"5px"} />
            <SHDotGroup {...dotGroupProps} paddingTop={"5px"} />
          </SHStack>
          <SHStack direction="row" spacing={1} alignItems="center" pb={"5px"}>
            {postfixLabel}
          </SHStack>
        </SHStack>
        {typeof subTitle === "string" ? (
          <SHHtmlBlock
            variant="body4"
            color={subTitleColor ? subTitleColor : palette.text.third}
            content={subTitle}
          />
        ) : (
          subTitle
        )}
      </SHStack>
      <SHStack direction="column">{children}</SHStack>
    </SHStack>
  );
};
