export const exportDate = {
  t: "d",
  z: "DD/MM/YY",
};

export const exportDateTime = {
  t: "d",
  z: "hh:mm DD/MM/YY",
  s: { alignment: { horizontal: "left" } },
};

export const exportCurrency = {
  t: "n",
  z: "$#,##0.###;[Red]-$#,##0.###",
};
export const exportNoDecimalCurrency = {
  t: "n",
  z: "$#,##0;[Red]-$#,##0",
};
export const exportPercentage = {
  t: "n",
  z: "0.###%",
};
export const exportNoDecimalPercentage = {
  t: "n",
  z: "0%",
};
export const fillLightBlue = {
  patternType: "solid",
  fgColor: {
    rgb: "d8e1f9",
  },
};
export const fillBlue = {
  patternType: "solid",
  fgColor: {
    rgb: "9eb4f0",
  },
};
export const fillLightYellow = {
  patternType: "solid",
  fgColor: {
    rgb: "fef5e3",
  },
};
export const fillYellow = {
  patternType: "solid",
  fgColor: {
    rgb: "feebc7",
  },
};
