import { HttpStatusCodes } from "@constants/http-status-codes";
import { PageRoutes } from "@constants/routes";
import { APIError, APIResponse, SHAxiosResponse } from "@models/core";
import { deserializeJson, reviveDateTime, toErrorMessage } from "@utils";
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { shHistory } from "src/App";
import { MINIMUM_SKELETON_DISPLAY_TIME } from "@constants";

axios.defaults.transformResponse = (response) => {
  return deserializeJson(response, reviveDateTime);
};

export const APIGatewayService: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SH_API_GW_URL,
  timeout: 0,
});

export const addAccessTokenInterceptor = (getAccessTokenSilently: any) => {
  APIGatewayService.interceptors.request.use(
    async (config: AxiosRequestConfig) => {
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      if (config?.headers) {
        config.headers.Authorization = `Bearer ${token}`;
        config.headers["Content-Type"] = "application/json";
      }
      return config;
    },
  );
};

export const refreshAccessToken = async (getAccessTokenSilently: any) => {
  return await getAccessTokenSilently({
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    ignoreCache: true,
  });
};

export const handleErrorProxy = <T, D>(
  response: SHAxiosResponse<T, D>,
  successMessage = "Success",
  serverErrorMessage = "Server Error",
) => {
  const apiResponse = new APIResponse();
  apiResponse.isSuccess = false;
  if (response.isCancel) {
    apiResponse.isCancel = true;
    apiResponse.message = "Canceled";
  }
  if (response.isNetError) {
    apiResponse.message = response.statusText ?? "Network error";
  }
  if (response.status === HttpStatusCodes.UNAUTHORIZED) {
    shHistory.push(PageRoutes.logout);
  }
  if (response.status >= 500) {
    apiResponse.message = serverErrorMessage;
  }
  if (response.status >= 400 && response.status < 499) {
    apiResponse.message =
      toErrorMessage(response.data as APIError) || "Bad request";
    apiResponse.isForbidden = response.status === HttpStatusCodes.FORBIDDEN;
  }
  if (response.status >= 200 && response.status < 299) {
    apiResponse.data = response.data;
    apiResponse.message = successMessage;
    apiResponse.isSuccess = true;
  }
  return apiResponse;
};

APIGatewayService.interceptors.response.use(
  (response: SHAxiosResponse) => {
    return response;
  },
  function (error: any) {
    if (error?.code === "ERR_NETWORK") {
      return {
        isNetError: true,
        data: undefined,
        config: {},
        headers: {},
        status: 0,
        statusText: error?.message,
      };
    }
    if (axios.isCancel(error)) {
      return {
        isCancel: true,
        data: undefined,
        config: {},
        headers: {},
        status: 0,
        statusText: "CanceledError",
      } as SHAxiosResponse;
    }
    return error.response;
  },
);

// Make animation smoother when API response too fast
export const getDataWithMinimumDelay = async <T>(
  callback: Promise<T>,
  delayMs: number = MINIMUM_SKELETON_DISPLAY_TIME,
): Promise<T> => {
  const response = await Promise.all([
    await callback,
    await new Promise((resolve) => setTimeout(resolve, delayMs)),
  ]);

  return response[0];
};
