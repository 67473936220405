export const exportDate = {
  t: "d",
  z: "DD/MM/YY",
  s: { alignment: { horizontal: "left" } },
};

export const exportDateTime = {
  t: "d",
  z: "hh:mm DD/MM/YY",
  s: { alignment: { horizontal: "left" } },
}

export const exportCurrency = {
  t: "n",
  z: "$#,##0;[Red]-$#,##0",
};
export const exportPercentage = {
  t: "n",
  z: "0.00%",
};
export const fillLightBlue = {
  patternType: "solid",
  fgColor: {
    rgb: "d8e1f9",
  },
};
export const fillBlue = {
  patternType: "solid",
  fgColor: {
    rgb: "9eb4f0",
  },
};
export const fillPink = {
  patternType: "solid",
  fgColor: {
    rgb: "fbd4e7",
  },
};
export const fillLightYellow = {
  patternType: "solid",
  fgColor: {
    rgb: "fef5e3",
  },
};
export const fillYellow = {
  patternType: "solid",
  fgColor: {
    rgb: "feebc7",
  },
};
export const fillGray = {
  patternType: "solid",
  fgColor: {
    rgb: "E7E6E6",
  },
};
