import { useInvestmentProduct } from "@hooks/useInvestmentProduct";
import { useUserPermissions } from "@hooks/userUserPermission";
import { RootState } from "@redux/store";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { UpgradeSubscriptionView } from "../components/common";
import { ViewStandard } from "../components/view-standard";
import { SHStack, SHTypography } from "@components/design-systems";
import { useTheme } from "@mui/material";
import { ViewStandardSkeleton } from "@pages/suppliers/_id/_products/investment-product/_id/view/components/view-standard/components/view-standard-skeleton";

export const ViewESGTab = () => {
  const { palette } = useTheme();
  const {
    investmentProductUI: { isEsgDataLoading },
    investmentProductEsgData,
  } = useSelector((state: RootState) => state.investmentProduct);
  const { isAdviserTrialGroup } = useUserPermissions();
  const { investmentProductId } = useParams();
  const { loadInvestmentProductEsg } = useInvestmentProduct();

  useEffect(() => {
    if (isAdviserTrialGroup) return;

    loadInvestmentProductEsg(investmentProductId ?? "new", "view");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investmentProductId]);

  const isLoadingData = useMemo(() => {
    return !investmentProductEsgData ? isEsgDataLoading : false;
  }, [investmentProductEsgData, isEsgDataLoading]);

  return isAdviserTrialGroup ? (
    <UpgradeSubscriptionView />
  ) : isLoadingData ? (
    <SHStack paddingY="25px">
      <ViewStandardSkeleton />
    </SHStack>
  ) : (
    <SHStack>
      <SHTypography
        variant="subtitle1"
        color={palette.text.primary}
        paddingY="25px"
      >
        Investment manager ESG profile
      </SHTypography>

      <ViewStandard
        sections={investmentProductEsgData?.group?.sections}
        investmentProductUpdateDate={
          investmentProductEsgData?.group?.pageUpdated
        }
      />
    </SHStack>
  );
};
