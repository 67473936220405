import { GrayDeleteButton } from "@components/buttons/gray-delete";
import { InformationButton } from "@components/buttons/information";
import { RollBackButton } from "@components/buttons/rollback";
import {
  SHAvatar,
  SHBox,
  SHButton,
  SHDataGrid,
  SHDataGridRef,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { DefaultDataState } from "@components/design-systems/sh-data-grid/constant";
import { StatusBadge } from "@components/status-badge";
import { EditSVG } from "@components/svgs";
import { APIExtRoutes, PageRoutes } from "@constants";
import { DateTimeFormat } from "@constants/format";
import { useFeatureTemplate } from "@hooks/useFeatureTemplate";
import { useNotification } from "@hooks/useNotification";
import { useUserPermissions } from "@hooks/userUserPermission";
import { TopBar, TopBarContainer } from "@layouts/top-bar";
import { FeatureTemplateListDTO } from "@models/feature-template/entities/featureTemplate";
import { FeatureTemplateStatus } from "@models/feature-template/enums/status";
import { Link, useTheme } from "@mui/material";
import { TextOverflowEllipsis } from "@pages/platform-analysis/components/text-overflow-ellipsis";
import {
  FeatureTemplateFilterOptions,
  RateCardFilterOptions,
} from "@pages/platform-analysis/constant";
import { RootState } from "@redux/store";
import { TableState, createColumnHelper } from "@tanstack/react-table";
import { nameOfFactory } from "@utils";
import { format } from "date-fns";
import { useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { generatePath, useNavigate } from "react-router";

const nameOf = nameOfFactory<FeatureTemplateListDTO>();
const columnHelper = createColumnHelper<FeatureTemplateListDTO>();
const pickColumns: (keyof FeatureTemplateListDTO)[] = [
  "id",
  "name",
  "notes",
  "ownerName",
  "ownerAvatarUrl",
  "lastModifiedDate",
  "selectedFeatures",
  "totalFeatures",
  "status",
];

export const ReviewFeatureTemplateList = () => {
  const fontSize = "13px";
  const { isAdviserTrialGroup } = useUserPermissions();
  const { palette } = useTheme();
  const { notify, closeNotify } = useNotification();
  const { updateFeatureTemplateStatus } = useFeatureTemplate();
  const [updatingStatusIds, setUpdatingStatusIds] = useState<string[]>([]);
  const { user } = useSelector((state: RootState) => state.auth);

  const navigate = useNavigate();
  const ref = useRef<SHDataGridRef | null>(null);
  const [gridState, setGridState] = useState<Partial<TableState>>({
    ...DefaultDataState,
    sorting: [{ id: nameOf("lastModifiedDate"), desc: true }],
    columnFilters: [
      {
        id: nameOf("status"),
        value: [FeatureTemplateStatus.Active],
      },
    ],
  });

  const handleOnEdit = () => {
    //TODO: Navigate to manage page here
    // navigate(
    //   generatePath(PageRoutes.featureTemplateDetail, {
    //     featureTemplateId: props.cell.getValue().id,
    //   }),
    // );

    notify("Editing coming soon.", {
      variant: "error",
      close: true,
    });
  };

  // const handleOnDuplicate = () => {
  //   notify("Duplication coming soon.", {
  //     variant: "error",
  //     close: true,
  //   });
  // };

  const handelOnChangeStatus = async (
    featureTemplateId?: string,
    status?: FeatureTemplateStatus,
  ) => {
    if (isAdviserTrialGroup) {
      notify(
        <SHTypography color={palette.text.white}>
          Upgrade your subscription
          <Link
            variant="body2"
            component="a"
            underline="always"
            color={palette.text.white}
            onClick={() => {
              navigate(
                `${generatePath(PageRoutes.practiceOverview, {
                  practiceId: user?.userMetadata?.adviser_firm_id,
                })}/subscription`,
              );
              closeNotify("update_status");
            }}
            sx={{
              paddingX: 0.5,
              cursor: "pointer",
              textDecorationColor: palette.text.white,
            }}
          >
            here
          </Link>
          to archive/unarchive Feature Template
        </SHTypography>,
        {
          variant: "error",
          close: true,
          width: 580,
          key: "update_status",
        },
      );
      return;
    }

    if (!featureTemplateId || !status) return;

    setUpdatingStatusIds((ids) => [...ids, featureTemplateId]);

    await updateFeatureTemplateStatus(featureTemplateId, status);
    ref.current?.refreshOdata();

    setUpdatingStatusIds((ids) => ids.filter((id) => id !== featureTemplateId));
  };

  const columns = useMemo(
    () => {
      const cols = [
        columnHelper.accessor((row) => row, {
          id: nameOf("name"),
          header: "Template name",
          cell: (props) => {
            return (
              <SHStack direction={"row"}>
                <TextOverflowEllipsis
                  fontWeight="bold"
                  value={props.cell.getValue().name ?? ""}
                />
                <SHBox
                  sx={{
                    display: "inline-flex",
                    alignItems: "flex-end",
                  }}
                >
                  {props.cell.getValue().notes ? (
                    <InformationButton content={props.cell.getValue().notes} />
                  ) : (
                    ""
                  )}
                </SHBox>
              </SHStack>
            );
          },
          enableColumnFilter: false,
          meta: {
            sx: {
              width: "20%",
              minWidth: "250px",
              padding: "0 16px !important",
            },
          },
        }),
        columnHelper.accessor((row) => row, {
          header: "Owner",
          id: nameOf("ownerName"),
          cell: (props) => {
            return (
              <SHStack direction={"row"} alignItems={"center"}>
                <SHAvatar
                  width={"24px"}
                  height={"24px"}
                  src={props.cell.getValue().ownerAvatarUrl}
                />
                <TextOverflowEllipsis
                  value={props.cell.getValue().ownerName ?? ""}
                  sx={{
                    marginLeft: "5px",
                    minWidth: "100%",
                  }}
                />
              </SHStack>
            );
          },
          enableColumnFilter: false,
          meta: {
            sx: {
              width: "12%",
              padding: "0 16px !important",
            },
          },
        }),
        columnHelper.accessor("lastModifiedDate", {
          header: "Last edit",
          cell: (props) => {
            const date = props.cell.getValue();
            return date ? format(new Date(date), DateTimeFormat) : "";
          },
          enableColumnFilter: false,
          meta: {
            sx: {
              width: "13%",
              minWidth: { xs: "130px" },
              padding: "0 0 0 25px !important",
            },
          },
        }),
        columnHelper.accessor((row) => row, {
          header: "Features selected",
          id: "selectedFeatures",
          cell: (props) => (
            <SHTypography
              fontSize={fontSize}
              lineHeight={"120%"}
              minWidth={"100px"}
            >
              {props.cell.getValue().selectedFeatures}/
              {props.cell.getValue().totalFeatures}
            </SHTypography>
          ),
          enableColumnFilter: false,
          meta: {
            filterData: FeatureTemplateFilterOptions,
            sx: {
              width: "13%",
              padding: "0 5px !important",
            },
          },
        }),
        columnHelper.accessor("status", {
          header: "Status",
          cell: (props) => (
            <StatusBadge
              style={{ width: "88px", fontSize: fontSize }}
              status={props.cell.getValue() as FeatureTemplateStatus}
            />
          ),
          meta: {
            filterData: RateCardFilterOptions,
            sx: {
              width: "10%",
              maxWidth: "120px",
              padding: "0 16px !important",
            },
          },
        }),
        columnHelper.accessor((row) => row, {
          id: "actionCol",
          header: "Action",
          enableColumnFilter: false,
          enableSorting: false,
          cell: (props) => (
            <SHStack
              direction="row"
              justifyContent={"flex-start"}
              spacing={0.5}
            >
              <SHButton
                sx={{ fontSize: fontSize }}
                startIcon={<EditSVG color={palette.common.white} />}
                variant="contained"
                disabled={isAdviserTrialGroup}
                onClick={handleOnEdit}
              >
                Edit
              </SHButton>
              {/* <DuplicateButton
                disabled={isAdviserTrialGroup}
                onClick={handleOnDuplicate}
              /> */}
              {props.cell.getValue().status ===
              FeatureTemplateStatus.Archived ? (
                <RollBackButton
                  disabled={updatingStatusIds.includes(
                    props.cell.getValue().id ?? "",
                  )}
                  isLoading={updatingStatusIds.includes(
                    props.cell.getValue().id ?? "",
                  )}
                  onClick={() =>
                    handelOnChangeStatus(
                      props.cell.getValue().id,
                      FeatureTemplateStatus.Active,
                    )
                  }
                />
              ) : (
                <GrayDeleteButton
                  disabled={updatingStatusIds.includes(
                    props.cell.getValue().id ?? "",
                  )}
                  isLoading={updatingStatusIds.includes(
                    props.cell.getValue().id ?? "",
                  )}
                  onClick={() =>
                    handelOnChangeStatus(
                      props.cell.getValue().id,
                      FeatureTemplateStatus.Archived,
                    )
                  }
                />
              )}
            </SHStack>
          ),
          meta: {
            sx: {
              width: "5%",
            },
          },
        }),
      ];
      return cols;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      <TopBarContainer>
        <TopBar
          title={"Suitability Reviews"}
          // TODO: Wait for implement
          // tools={
          //   <>
          //     <PlusButton
          //       variant="contained"
          //       size="extraMedium"
          //       onClick={() => {
          //         navigate(
          //           generatePath(PageRoutes.featureTemplateDetail, {
          //             featureTemplateId: "new",
          //           }),
          //         );
          //       }}
          //     >
          //       New template
          //     </PlusButton>
          //   </>
          // }
        />
      </TopBarContainer>
      <SHBox sx={{ marginTop: "25px" }}>
        <SHDataGrid
          ref={ref}
          odata={{
            url: APIExtRoutes.odataFeatureTemplate,
            pickColumns: pickColumns,
            globalFilterColumns: [nameOf("name"), nameOf("ownerName")],
          }}
          emptyMessage={"No feature template."}
          columns={columns as any}
          state={gridState}
          onChangeState={(state) => setGridState(state)}
          searchBoxProps={{
            placeholder: "Search name and owners",
          }}
        />
      </SHBox>
    </>
  );
};
