import { InformationButton } from "@components/buttons/information";
import {
  SHHtmlBlock,
  SHStack,
  SHTable,
  SHTableBody,
  SHTableContainer,
  SHTableHead,
  SHTableRow,
  SHTypography,
} from "@components/design-systems";
import { SHBooleanIcon } from "@components/design-systems/sh-boolean-icon";
import { DateFormat, FullDateFormat } from "@constants/format";
import { FieldTypeId } from "@models/configuration";
import {
  InvestmentProductConfigurationModelSection,
  InvestmentProductConfigurationModelsFeature,
  ProductModelValueDTO,
} from "@models/product/investment-product/entities/model";
import { useTheme } from "@mui/material";
import { FRCell } from "@pages/suppliers/_id/_products/investment-product/_id/view/components/table-cell";
import { ReadMoreButton } from "@pages/suppliers/_id/_products/investment-product/_id/view/components/view-feature-model/components/read-more";
import { TableDateFormatter } from "@pages/suppliers/_id/_products/investment-product/_id/view/components/view-feature-model/components/table-date-formatter";
import {
  defaultCellWidth,
  defaultFirstCellWidth,
  defaultNumOfCell,
  defaultNumOfHeadCol,
  defaultTextLength,
  headerPadding,
} from "@pages/suppliers/_id/_products/investment-product/_id/view/components/view-feature-model/configs";
import {
  checkStringIsNumber,
  isSubColumn,
  tryParseJson,
} from "@pages/suppliers/_id/util";
import { format, parse } from "date-fns";
import { isEmpty, isEqual } from "lodash";
import { NumericFormat } from "react-number-format";
import Balancer from "react-wrap-balancer";
import { GetAssetAllocationFeatureData } from "../../../../utils";
import { AdditionalVisualizeTable } from "@pages/suppliers/constants";
interface InvestmentTableItemProps {
  section: InvestmentProductConfigurationModelSection;
  width: number;
  alignEnd?: boolean;
}
export const InvestmentTableItem = ({
  section,
  width,
  alignEnd = false,
}: InvestmentTableItemProps) => {
  const { palette } = useTheme();
  const isAssetAllocationSection = isEqual(
    section.name,
    AdditionalVisualizeTable.ASSET_ALLOCATION,
  );
  const isAssetAllocationNotesTable = isEqual(
    section.name,
    AdditionalVisualizeTable.ASSET_ALLOCATION_NOTES,
  );
  let {
    tableHeaderCategory,
    features: sectionFeatures,
    name: sectionName,
    nameExtension: sectionNameExtension,
  } = section;
  if (isEmpty(sectionFeatures)) return <></>;

  if (isAssetAllocationSection) {
    sectionFeatures = GetAssetAllocationFeatureData(sectionFeatures ?? []);
  }

  let cellWidth = defaultCellWidth;
  let firstCellWidth = defaultFirstCellWidth;
  let numOfCell = defaultNumOfCell;
  let numberOfHeadCol = defaultNumOfHeadCol;

  if (tableHeaderCategory?.values) {
    numOfCell = tableHeaderCategory?.values.length;
    cellWidth = (width - headerPadding) / (numOfCell + 1);
    firstCellWidth = width / (numOfCell + 1) + headerPadding;
  }
  // Specific cell width for Asset allocation notes section
  if (isAssetAllocationNotesTable) {
    cellWidth = cellWidth + 40;
    sectionFeatures = sectionFeatures?.filter(
      (feature) => !isEmpty(feature.productData?.value),
    );
  }
  const getMaxTextLength = (
    isTable: boolean,
    features?: InvestmentProductConfigurationModelsFeature[] | null,
  ) => {
    if (!isTable || !features) return defaultTextLength;

    if (features.length === 1) {
      return 40;
    }

    return 10;
  };

  if (isEmpty(tableHeaderCategory?.values)) {
    numberOfHeadCol += 1;
  }
  const getFeatureValue = (
    feature: InvestmentProductConfigurationModelsFeature,
    productData: ProductModelValueDTO,
  ) => {
    const value = productData?.value;

    let fieldType = feature.fieldType?.id;
    if (feature.fieldType?.id === FieldTypeId.Composite) {
      fieldType = feature.secondaryFieldType?.id;
    }

    switch (fieldType) {
      case FieldTypeId.YesNo: {
        switch (value?.toString()) {
          case "true":
            return <SHBooleanIcon isTrue />;
          case "false":
            return <SHBooleanIcon isTrue={false} />;
        }
        break;
      }

      /* Currency */
      case FieldTypeId.Currency:
      case FieldTypeId.CurrencyText: {
        if (isEmpty(value)) {
          return (
            <SHTypography
              whiteSpace={"nowrap"}
              variant="caption"
              colorVariant="fourth"
            >
              No data
            </SHTypography>
          );
        }
        const isNumber = checkStringIsNumber(value);
        if (!isNumber) {
          return (
            <SHTypography variant="body3" colorVariant="fourth">
              {value}
            </SHTypography>
          );
        }
        return (
          <SHTypography variant="body3" colorVariant="fourth">
            <NumericFormat
              valueIsNumericString
              decimalScale={2}
              displayType="text"
              value={value}
              prefix="$"
            />
          </SHTypography>
        );
      }
      case FieldTypeId.DateTime: {
        if (isEmpty(value)) {
          return (
            <SHTypography
              whiteSpace={"nowrap"}
              variant="caption"
              colorVariant="fourth"
            >
              No data
            </SHTypography>
          );
        }
        const dateValue = parse(value as string, FullDateFormat, new Date());

        return (
          <SHTypography variant="body3" colorVariant="fourth">
            {format(dateValue, DateFormat)}
          </SHTypography>
        );
      }

      /* Percentage */
      case FieldTypeId.Percentage:
      case FieldTypeId.PercentageText: {
        if (isEmpty(value)) {
          return (
            <SHTypography
              whiteSpace={"nowrap"}
              variant="caption"
              colorVariant="fourth"
            >
              No data
            </SHTypography>
          );
        }
        const isNumber = checkStringIsNumber(value);
        if (!isNumber) {
          return (
            <SHTypography variant="body3" colorVariant="fourth">
              {value}
            </SHTypography>
          );
        }
        return (
          <SHTypography variant="body3" colorVariant="fourth">
            <NumericFormat
              valueIsNumericString
              decimalScale={2}
              displayType="text"
              value={value}
              suffix="%"
            />
          </SHTypography>
        );
      }
      /* Numeric */
      case FieldTypeId.NumberText:
      case FieldTypeId.Number: {
        if (isEmpty(value)) {
          return (
            <SHTypography
              whiteSpace={"nowrap"}
              variant="caption"
              colorVariant="fourth"
            >
              No data
            </SHTypography>
          );
        }
        const isNumber = checkStringIsNumber(value);
        if (!isNumber) {
          return (
            <SHTypography variant="body3" colorVariant="fourth">
              {value}
            </SHTypography>
          );
        }
        return (
          <SHTypography variant="body3" colorVariant="fourth">
            <NumericFormat
              valueIsNumericString
              displayType="text"
              value={value}
              thousandSeparator=","
              decimalScale={2}
              decimalSeparator="."
            />
          </SHTypography>
        );
      }

      /* Dropdown */

      case FieldTypeId.Dropdown: {
        const lov = feature.lovData;
        const value = feature.productData?.value;
        const displayValue = lov?.find((x) => x.id === value)?.name;
        if (isEmpty(displayValue)) {
          return (
            <SHTypography
              whiteSpace={"nowrap"}
              variant="caption"
              colorVariant="fourth"
            >
              No data
            </SHTypography>
          );
        }

        return (
          <SHHtmlBlock
            variant="body3"
            color={palette.text.fourth}
            content={displayValue}
          />
        );
      }

      default: {
        if (value === undefined || value === "") {
          return (
            <SHTypography
              whiteSpace={"nowrap"}
              variant="caption"
              colorVariant="fourth"
            >
              No data
            </SHTypography>
          );
        }
        const maxTextLength = getMaxTextLength(
          isAssetAllocationNotesTable,
          sectionFeatures,
        );
        const words = value.toString().split(" ");
        let hasMoreWords = words.length > maxTextLength;

        const displayContent = hasMoreWords
          ? words.slice(0, maxTextLength).join(" ")
          : value;

        return (
          <>
            <SHStack
              sx={{
                color: palette.text.fourth,
                fontWeight: 500,
                fontSize: "0.813rem",
                fontFamily: "Epilogue",
              }}
            >
              <Balancer>{displayContent}</Balancer>
            </SHStack>

            {hasMoreWords && (
              <>
                &nbsp;
                <ReadMoreButton content={value} />
              </>
            )}
          </>
        );
      }
    }
  };
  return (
    <SHTableContainer
      sx={{
        overflowY: "hidden",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: alignEnd ? "flex-end" : "flex-start",
        alignItems: alignEnd ? "flex-end" : "flex-start",
      }}
    >
      <SHTable>
        {/* Header */}
        <SHTableHead>
          <SHTableRow
            sx={{
              th: {
                height: "50.26px",
                lineHeight: "1rem !important",
                paddingY: "0px !important",
              },
            }}
          >
            <FRCell
              sx={{
                width: `${cellWidth}% !important`,
              }}
              colSpan={numberOfHeadCol}
            >
              <SHTypography
                lineHeight="1rem !important"
                variant="body4"
                fontWeight={600}
              >
                <Balancer>
                  {sectionName}
                  <TableDateFormatter dateString={sectionNameExtension} />
                </Balancer>
              </SHTypography>
            </FRCell>
            {tableHeaderCategory?.values.map((product) => {
              return (
                <FRCell
                  key={product.id}
                  sx={{
                    width: `${cellWidth}% !important`,
                    paddingLeft: "10px !important",
                  }}
                >
                  <SHStack direction="row" flexWrap={"nowrap"}>
                    <SHTypography
                      variant="body4"
                      fontWeight={600}
                      lineHeight="1rem !important"
                    >
                      {product.name}
                    </SHTypography>
                    {!isEmpty(product.description) && (
                      <InformationButton content={product.description} />
                    )}
                  </SHStack>
                </FRCell>
              );
            })}
          </SHTableRow>
        </SHTableHead>
        {/* Body */}
        <SHTableBody
          sx={{
            "&.MuiTableBody-root::before": {
              lineHeight: "0px",
              fontSize: "0px",
            },
          }}
        >
          {/* Empty row for separation between header and body */}
          <SHTableRow
            style={{
              height: "13px",
              border: 0,
              borderBottomWidth: 1,
              background: "transparent",
            }}
          ></SHTableRow>
          {/* Features */}
          {sectionFeatures?.map((feature, index) => {
            let productDataValues: ProductModelValueDTO[] | string =
              tryParseJson(feature.productData?.value as string);

            if (typeof productDataValues !== "object") {
              productDataValues = [
                { headerId: "", value: productDataValues.toString() },
              ];
            }
            if (isEmpty(feature.productData?.value)) {
              productDataValues = [{ headerId: "", value: "" }];
            }

            return (
              <SHTableRow
                key={feature.id}
                sx={{
                  borderLeftWidth: 1,
                  borderRightWidth: 1,
                  borderTopWidth: index !== 0 ? 0 : 1,
                  backgroundColor:
                    index % 2 === 0
                      ? `${palette.secondary[50]} !important`
                      : "transparent !important",
                  td: { overflow: "hidden" },
                }}
              >
                <FRCell
                  sx={{
                    width: `${firstCellWidth}% !important`,
                    lineHeight: "1rem !important",
                    height: 5,
                    // subcolumn have more indent from the left
                    paddingLeft: isSubColumn(feature.name) ? 3.5 : undefined,
                    paddingRight: 0,
                  }}
                >
                  <Balancer>
                    <SHStack direction="row">
                      <SHTypography
                        variant="body4"
                        lineHeight="1rem !important"
                        // subcolumn not bold
                        fontWeight={isSubColumn(feature.name) ? undefined : 600}
                      >
                        {feature.name}
                      </SHTypography>
                      {!isEmpty(feature.description) && (
                        <InformationButton content={feature.description} />
                      )}
                    </SHStack>
                  </Balancer>
                </FRCell>
                {productDataValues?.map((value) => {
                  const numberOfOtherCell =
                    numOfCell - ((productDataValues.length ?? 1) - 1);
                  return (
                    <FRCell
                      key={value.headerId}
                      sx={{
                        width: `${cellWidth}% !important`,
                        lineHeight: "1rem !important",
                        paddingLeft:
                          productDataValues.length > 1 ? "10px" : undefined,
                      }}
                      colSpan={numberOfOtherCell}
                    >
                      <SHTypography
                        variant="body4"
                        lineHeight="1rem !important"
                      >
                        {getFeatureValue(feature, value)}
                      </SHTypography>
                    </FRCell>
                  );
                })}
              </SHTableRow>
            );
          })}
        </SHTableBody>
      </SHTable>
    </SHTableContainer>
  );
};
