import { SHBox, SHBoxProps } from "@components/design-systems";
import { TypographyPropsVariantOverrides, useTheme } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import { OverridableStringUnion } from "@mui/types";
import htmlParser from "html-react-parser";
import { useMemo } from "react";
export interface SHHtmlBlockProps extends SHBoxProps {
  content?: string | null; //html
  placeholder?: string;
  variant?: OverridableStringUnion<
    Variant | "inherit",
    TypographyPropsVariantOverrides
  >;
  color?: string;
}

export const SHHtmlBlock = ({
  content,
  placeholder,
  variant = "body2",
  color,
  ...others
}: SHHtmlBlockProps) => {
  const { palette, components, typography } = useTheme();

  const variantStyle = useMemo(() => {
    if (!components?.MuiTypography?.styleOverrides || !typography) return {};
    const mapping: any = {
      ...typography,
      ...components.MuiTypography.styleOverrides,
    };
    return mapping[variant];
  }, [variant, components?.MuiTypography?.styleOverrides, typography]);

  const fontWeight = useMemo(() => {
    return Math.max(700, Math.min((variantStyle?.fontWeight ?? 0) + 300, 900));
  }, [variantStyle?.fontWeight]);

  return (
    <SHBox
      {...others}
      sx={{
        "&, & *": {
          m: 0,
          lineHeight: others.lineHeight ?? "160%",
          textAlign: others.textAlign ?? "justify",
          color: color ? color : palette.text.primary,
          ...variantStyle,
        },
        "& strong, & strong *": {
          fontWeight,
        },
        opacity: content ? 1 : 0.5,
        ...others.sx,
      }}
    >
      {content ? htmlParser(content) : placeholder}
    </SHBox>
  );
};
