import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { SHBox, SHTypography, SHStack } from "@components/design-systems";
import { ArrowButton } from "@components/buttons/arrow";
import { useMediaQuery, useTheme } from "@mui/material";
import { generateUUID, hexToRGBA } from "@utils";
import { useState } from "react";
import { S3ObjectDTO } from "@models/core";
import { ReactImageGalleryItem } from "react-image-gallery";
import { ImageGalleryDialog } from "@components/image-gallery-dialog";
import { chunk, isEmpty } from "lodash";
import { ImageItem } from "@components/grid-image-gallery/image-item";

export interface GridImageGalleryProps {
  images: S3ObjectDTO[];
  containerPadding?: number | string;
  footerPadding?: number | string;
  specifyClass?: string;
  paginationClass?: string;
  previewImagesDialogTitle?: string;
  handleOnDelete?: (imageId: string) => void;
}

const defaultContainerPadding = "15px 15px 0 15px";
const defaultFooterPadding = "13px 0";
const GRID_CONFIG = {
  desktop: {
    columns: 3,
    imagesPerSlide: 6,
  },
  mobile: {
    columns: 2,
    imagesPerSlide: 4,
  },
} as const;

export const GridImageGallery = ({
  images,
  containerPadding = defaultContainerPadding,
  footerPadding = defaultFooterPadding,
  specifyClass = `a${generateUUID()}`,
  paginationClass = `a${generateUUID()}`,
  previewImagesDialogTitle = "Images",
  handleOnDelete,
}: GridImageGalleryProps) => {
  const { palette, breakpoints } = useTheme();
  const isSmallScreen = useMediaQuery(breakpoints.down("md"));

  const currentConfig = isSmallScreen
    ? GRID_CONFIG.mobile
    : GRID_CONFIG.desktop;
  const chunkSize = currentConfig.imagesPerSlide;
  const columns = currentConfig.columns;

  const slides = chunk(images, chunkSize);

  const isShowArrow = images.length > chunkSize;
  const [carouselLoaded, setCarouselLoaded] = useState(false);
  const [showPreviewImage, setShowPreviewImage] = useState(false);
  const [startPreviewImageIndex, setStartPreviewImageIndex] = useState(-1);
  const previewImages: ReactImageGalleryItem[] = images.map(
    (item: S3ObjectDTO) => {
      return {
        original: `${item.url}`,
      };
    },
  ) as ReactImageGalleryItem[];
  const handleSwiper = (swiper: any) => {
    setCarouselLoaded(true);
  };
  if (isEmpty(images)) {
    return (
      <SHStack
        alignItems="center"
        justifyContent={"center"}
        sx={{
          padding: "10px 10px",
          backdropFilter: "blur(0.5px)",
          background: `${hexToRGBA("#c5ddfd", 0.1)}`,
          border: `1px solid ${palette.secondary[100]}`,
        }}
      >
        <SHTypography variant="body4">No images.</SHTypography>
      </SHStack>
    );
  }

  return (
    <>
      <SHBox
        sx={{
          width: "100%",
          padding: `${
            images && images.length > 3
              ? containerPadding
              : defaultContainerPadding
          }`,
          backgroundColor: hexToRGBA(palette.secondary[200], 0.1),
          border: `1px solid ${palette.secondary[100]}`,
          backdropFilter: "blur(0.5px)",
          "& .swiper-slide": {
            display: "grid",
            height: "auto !important",
            maxHeight: 420,
            gap: "15px",
            overflow: "hidden",
            gridTemplateColumns: `repeat(${columns}, 1fr)`,
            gridTemplateRows: (theme) =>
              `repeat(${Math.ceil(
                slides[0].length / columns,
              )}, minmax(0, 1fr))`,
            boxSizing: "border-box",
            // Firefox specific fix
            "@-moz-document url-prefix()": {
              height: "fit-content !important",
              "& > *": {
                height: "auto !important",
              },
            },
            "& > *": {
              aspectRatio: "4/3",
              width: "100%",
              height: "100%",
              // Firefox specific fix for aspect ratio
              "@-moz-document url-prefix()": {
                minHeight: 0,
                height: "auto !important",
              },
            },
          },
        }}
      >
        <Swiper
          loop={false}
          observer
          centeredSlides={false}
          centeredSlidesBounds={false}
          modules={[Navigation, Pagination]}
          //add swiper custom navigation
          navigation={{
            prevEl: `.btnPrev-${specifyClass}`,
            nextEl: `.btnNext-${specifyClass}`,
          }}
          //add swiper custom pagination
          pagination={{
            el: `.swiper-pagination-${specifyClass}`,
            type: "bullets",
            clickable: true,
          }}
          onObserverUpdate={(swiper) => {
            // Re-init navigation
            swiper.navigation.destroy();
            swiper.navigation.init();
            swiper.navigation.update();
          }}
          onSwiper={handleSwiper}
          onSlideChange={() => {
            if (!carouselLoaded) {
              setCarouselLoaded(true);
            }
          }}
        >
          {slides.map((slide, index) => (
            <SwiperSlide key={index}>
              {slide.map((image: S3ObjectDTO, index: number) => (
                <ImageItem
                  key={image.key}
                  image={image}
                  handleOnDelete={handleOnDelete}
                  handleOnZoomOut={(imageId) => {
                    setShowPreviewImage(true);
                    setStartPreviewImageIndex(
                      images?.findIndex((image) => image.key === imageId),
                    );
                  }}
                />
              ))}
            </SwiperSlide>
          ))}
        </Swiper>

        <SHBox
          sx={{
            display: "flex",
            flexDirection: "row",
            columnGap: "16px",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 3,
            padding: `${
              images && images.length > 3 ? footerPadding : defaultFooterPadding
            }`,
            margin: "auto",
          }}
        >
          {isShowArrow && (
            <ArrowButton
              className={`btnPrev-${specifyClass}`}
              direction="previous"
              hidden={!carouselLoaded}
            />
          )}

          {/* custom pagination  */}
          <SHBox
            className={`${paginationClass} swiper-pagination-${specifyClass}`}
            sx={{
              [`&.${paginationClass}`]: {
                textAlign: "center",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                columnGap: "8px",
                rowGap: "4px",
                flexWrap: "wrap",
                "&>.swiper-pagination-bullet": {
                  display: "inline-block",
                  backgroundColor: "transparent",
                  transition: "all 0.2s ease",
                  width: "10px",
                  height: "10px",
                  border: `1px solid ${palette.text.disabled}`,
                  opacity: 1,
                  cursor: "pointer",

                  "&-active": {
                    backgroundColor: palette.text.third,
                    width: "28px",
                    borderRadius: "24px",
                  },
                },
              },
            }}
          ></SHBox>
          {isShowArrow && (
            <ArrowButton
              className={`btnNext-${specifyClass}`}
              direction="next"
              hidden={!carouselLoaded}
            />
          )}
        </SHBox>
      </SHBox>
      {showPreviewImage && (
        <ImageGalleryDialog
          open={!isEmpty(previewImages)}
          title={previewImagesDialogTitle}
          items={previewImages}
          startIndex={startPreviewImageIndex}
          onClose={() => setShowPreviewImage(false)}
        />
      )}
    </>
  );
};
