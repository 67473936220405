import { InformationButton } from "@components/buttons/information";
import {
  SHBox,
  SHStack,
  SHTableRow,
  SHTableRowProps,
  SHTypography
} from "@components/design-systems";
import { CheckSVG, CrossSVG } from "@components/svgs";
import { FieldTypeId } from "@models/configuration";
import { AnalysisDataValue } from "@models/platform-analysis/enums/dataValue";
import {
  ReviewDataDTO,
  ReviewFeatureDTO,
  ReviewSelectedProductDTO,
} from "@models/reviews/entities/steps/feature";
import { useTheme } from "@mui/material";
import { renderBooleanIcon } from "@pages/platform-analysis/_id/steps/business-metric/analysis/components/feature-row";
import { ClickToViewPopup } from "@pages/platform-analysis/components/click-to-view-popup";
import {
  FeatureReviewTableCell,
  FeatureReviewTableCellProps,
} from "@pages/reviews/_id/steps/feature/analysis/components/table-cell";
import { generateReviewColorCell } from "@pages/reviews/_id/steps/feature/analysis/util";
import { hexToRGBA } from "@utils";
import { isEqual, isNil } from "lodash";

interface FeatureReviewFeatureTableRowProps {
  featureData: ReviewFeatureDTO;
  selectedProducts?: ReviewSelectedProductDTO[];
}

export const ReviewBusinessFeatureTableRow = ({
  featureData,
  selectedProducts,
  ...others
}: FeatureReviewFeatureTableRowProps &
  FeatureReviewTableCellProps &
  SHTableRowProps) => {
  const { palette } = useTheme();

  const renderPlatformValue = (reviewData: ReviewDataDTO) => {
    if (isEqual(reviewData?.value, AnalysisDataValue.MissingData))
      return (
        <SHTypography
          variant="body3"
          zIndex="1"
          colorVariant="third"
          textAlign="center"
        >
          No data
        </SHTypography>
      );
    if (isEqual(reviewData?.value, AnalysisDataValue.True)) return <CheckSVG />;
    if (isEqual(reviewData?.value, AnalysisDataValue.False)) return <CrossSVG />;

    switch (reviewData?.fieldTypeId) {
      case FieldTypeId.TextShort:
        return (
          <SHStack gap={"5px"} alignItems={"center"} direction="row">
            <SHTypography
              variant="body3"
              zIndex="1"
              colorVariant="third"
              textAlign="left"
            >
              {reviewData?.value}
            </SHTypography>
            <SHBox>
              {!isNil(reviewData?.isPositiveScore) &&
                renderBooleanIcon(reviewData?.isPositiveScore)}
            </SHBox>
          </SHStack>
        );
      case FieldTypeId.Number:
        return (
          <>
            <SHTypography
              variant="body3"
              zIndex="1"
              colorVariant="third"
              textAlign="justify"
            >
              {reviewData?.value}
            </SHTypography>
            {!isNil(reviewData?.isPositiveScore) &&
              renderBooleanIcon(reviewData?.isPositiveScore)}
          </>
        );
      case FieldTypeId.TextLong:
        return <ClickToViewPopup analysisData={reviewData} />;
      default:
        return (
          <SHTypography
            variant="body3"
            zIndex="1"
            colorVariant="third"
            textAlign="justify"
          >
            {reviewData?.value}
          </SHTypography>
        );
    }
  };

  return (
    <SHTableRow
      sx={{
        height: 54,
        background: `${hexToRGBA(palette.secondary[200], 0.1)} !important`,
      }}
      {...others}
    >
      <FeatureReviewTableCell isFirstColumn>
        <SHStack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          spacing={2}
        >
          <SHTypography
            variant="body3"
            colorVariant="third"
            textAlign={"left"}
            lineHeight="120%"
          >
            {featureData?.name}
          </SHTypography>
          <SHStack
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            spacing={1}
          >
            {featureData?.description && (
              <InformationButton content={featureData.description} />
            )}
          </SHStack>
        </SHStack>
      </FeatureReviewTableCell>
      {featureData?.analysisData?.map((analysisData: ReviewDataDTO) => {
        const platform = selectedProducts?.find(
          (selectedProduct: ReviewSelectedProductDTO) =>
            selectedProduct.id === analysisData.productId,
        );
        if (platform)
          return (
            <FeatureReviewTableCell
              key={analysisData?.productId}
              colorCell={generateReviewColorCell(
                platform.decisionStatus,
                platform.isMissingEssential,
              )}
            >
              <SHStack
                direction={"row"}
                alignItems={"center"}
                justifyContent="center"
              >
                {renderPlatformValue(analysisData)}
              </SHStack>
            </FeatureReviewTableCell>
          );
        return <></>;
      })}
    </SHTableRow>
  );
};
