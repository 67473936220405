import { SHTextField, SHTextFieldProps } from "@components/design-systems";
import { isEmpty } from "lodash";
import React, { useMemo } from "react";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import { BaseType } from "typescript";

export interface SHNumericFieldProps
  extends Omit<SHTextFieldProps, "value" | "onChange"> {
  value?: number | null;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    value: number | null,
  ) => void;
  min?: number;
  max?: number;
  numericFormatProps?: Partial<NumericFormatProps<BaseType>>;
}

export const SHNumericField = ({
  value,
  onChange,
  numericFormatProps,
  min,
  max,
  ...others
}: SHNumericFieldProps) => {
  const NumberFormatField = useMemo(
    () =>
      React.forwardRef<any, NumericFormatProps<BaseType>>((compProps, ref) => {
        const propsCompiled = { ...compProps, ...numericFormatProps };
        return (
          <NumericFormat
            {...propsCompiled}
            getInputRef={ref}
            onValueChange={(values, sourceInfo) => {
              if (onChange) {
                onChange(
                  sourceInfo.event as React.ChangeEvent<
                    HTMLInputElement | HTMLTextAreaElement
                  >,
                  isEmpty(values.value) ? null : Number(values.value),
                );
              }
            }}
            isAllowed={
              propsCompiled.isAllowed
                ? propsCompiled.isAllowed
                : (values) => {
                    const { floatValue } = values;
                    if (floatValue === undefined) return true;
                    if (min !== undefined && floatValue < min) return false;
                    if (max !== undefined && floatValue > max) return false;
                    return true;
                  }
            }
          />
        );
      }),
    // eslint-disable-next-line
    [],
  );

  return (
    <SHTextField
      {...others}
      value={value ?? ""}
      InputProps={{
        inputComponent: NumberFormatField as any,
        ...others.InputProps,
      }}
    />
  );
};
