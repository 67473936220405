import mainBgImage from "@assets/images/background/main.svg";
import { AnalysisNavBars } from "@components/analysis-nav-bar";
import {
  SHContainer,
  SHStack,
  SHTab,
  SHTabs,
} from "@components/design-systems";
// import InactivityDialog from "@components/dialogs/inactivity";
import { TopBarPlacement } from "@layouts/top-bar";
import { useMemo } from "react";
import { Outlet } from "react-router-dom";

export const AnalysisLayout = (): JSX.Element => {
  return (
    <SHStack
      sx={{
        backgroundAttachment: "fixed",
        width: "100vw",
        height: "100vh",
        overflow: "auto",
        backgroundImage: `url(${mainBgImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
      direction="column"
    >
      <AnalysisNavBars />
      <Outlet />
      {/* <InactivityDialog /> */}
    </SHStack>
  );
};

export const AnalysisListViewLayout = (): JSX.Element => {
  const tabs: SHTab[] = useMemo(
    () => [
      {
        label: "Analysis",
        linkTo: "",
      },
      {
        label: "Feature templates",
        linkTo: "feature-template",
      },
      {
        label: "Rate cards",
        linkTo: "rate-card",
      },
    ],
    [],
  );

  return (
    <SHContainer maxWidth="lg2" sx={{ px: { xs: "16px", lg2: 0 } }}>
      <SHStack spacing={2} paddingY={3}>
        <TopBarPlacement />
        <SHTabs tabs={tabs} isNavLink />
        <Outlet />
      </SHStack>
    </SHContainer>
  );
};
