import { InformationButton } from "@components/buttons/information";
import {
  SHBox,
  SHStack,
  SHTableRow,
  SHTableRowProps,
  SHTypography,
} from "@components/design-systems";
import { CheckSVG, CrossSVG } from "@components/svgs";
import { FieldTypeId } from "@models/configuration";
import {
  AnalysisDataDTO,
  AnalysisFeatureDTO,
} from "@models/platform-analysis/entities/steps/feature";
import { AnalysisDataValue } from "@models/platform-analysis/enums/dataValue";
import {
  FeatureAnalysisTableCell,
  FeatureAnalysisTableCellProps,
} from "@pages/platform-analysis/_id/steps/feature/analysis/components/table-cell";
import { ClickToViewPopup } from "@pages/platform-analysis/components/click-to-view-popup";
import { toNativeValue } from "@pages/suppliers/_id/_products/_id/edit/components/tab-form/util";
import { hexToRGBA } from "@utils";
import { isNil } from "lodash";

interface BusinessMetricFeatureTableRowProps {
  featureData?: AnalysisFeatureDTO;
}

export const renderBooleanIcon = (value: boolean) => {
  if (value) return <CheckSVG width={18} height={18} />;
  return <CrossSVG width={18} height={18} />;
};

export const BusinessMetricFeatureTableRow = ({
  featureData,
  ...others
}: BusinessMetricFeatureTableRowProps &
  FeatureAnalysisTableCellProps &
  SHTableRowProps) => {
  const renderPlatformValue = (analysisData: AnalysisDataDTO) => {
    if (analysisData?.value === AnalysisDataValue.MissingData)
      return (
        <SHTypography
          variant="body3"
          zIndex="1"
          colorVariant="third"
          textAlign="center"
        >
          No data
        </SHTypography>
      );

    switch (analysisData?.fieldTypeId) {
      case FieldTypeId.YesNo:
        return renderBooleanIcon(
          toNativeValue(analysisData?.value ?? "", FieldTypeId.YesNo),
        );
      case FieldTypeId.TextShort:
        return (
          <SHStack gap={"5px"} alignItems={"center"} direction="row">
            <SHTypography
              variant="body3"
              zIndex="1"
              colorVariant="third"
              textAlign="left"
            >
              {analysisData?.value}
            </SHTypography>
            <SHBox>
              {!isNil(analysisData?.isPositiveScore) &&
                renderBooleanIcon(analysisData?.isPositiveScore)}
            </SHBox>
          </SHStack>
        );
      case FieldTypeId.Number:
        return (
          <>
            <SHTypography
              variant="body3"
              zIndex="1"
              colorVariant="third"
              textAlign="justify"
            >
              {analysisData?.value}
            </SHTypography>
            {!isNil(analysisData?.isPositiveScore) &&
              renderBooleanIcon(analysisData?.isPositiveScore)}
          </>
        );
      case FieldTypeId.TextLong:
        return <ClickToViewPopup analysisData={analysisData} />;
      default:
        return (
          <SHTypography
            variant="body3"
            zIndex="1"
            colorVariant="third"
            textAlign="justify"
          >
            {analysisData?.value}
          </SHTypography>
        );
    }
  };

  return (
    <SHTableRow
      sx={{ height: 54, background: `${hexToRGBA("#c5ddfd", 0.1)} !important` }}
      {...others}
    >
      <FeatureAnalysisTableCell isFirstColumn>
        <SHStack
          direction={"row"}
          alignItems={"center"}
          justifyContent="space-between"
        >
          <SHTypography
            variant="body3"
            colorVariant="third"
            textAlign="left"
            lineHeight="120%"
          >
            {featureData?.name}
          </SHTypography>
          {featureData?.description && (
            <InformationButton content={featureData.description} />
          )}
        </SHStack>
      </FeatureAnalysisTableCell>
      {featureData?.analysisData?.map((analysisData: any) => (
        <FeatureAnalysisTableCell key={analysisData?.platformId}>
          <SHStack
            direction={"row"}
            alignItems={"center"}
            justifyContent="center"
          >
            {renderPlatformValue(analysisData)}
          </SHStack>
        </FeatureAnalysisTableCell>
      ))}
    </SHTableRow>
  );
};
