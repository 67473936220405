import { SHContainer, SHStack, SHTypography } from "@components/design-systems";
import { TopBar, TopBarContainer } from "@layouts/top-bar";
import { useState } from "react";
import { ExportMAListExcelButton } from "./components/buttons/export-excel";
import { SMATableDisclaimerDialog } from "./components/dialogs/disclaimer";
import { SMAListVirtualized } from "./components/tables";
import { DisclaimerAssumptionsButton } from "@components/buttons/disclaimer-assumptions";
import { useEffectOnce } from "react-use";
import { getInvestmentFiltersPlatforms } from "@services/investment/investmentService";
import {
  SMAListCurrentlySelectedFilter,
  SMAListFavouriteFilter,
  SMAListMinimumInvestmentSizeFilter,
} from "@models/managed-accounts/enums/filter";
import { useDispatch } from "react-redux";
import { updateFilters } from "@redux/slices/managed-account/sma-list";
import { useSelector } from "react-redux";
import { RootState } from "@redux/store";
import { isEqual } from "lodash";
import { CalculatedInvestmentDTO } from "@pages/managed-accounts/sma-list/components/tables/model";
import { FeesDisplayStyle } from "@models/platform-analysis/enums/fee/displayStyle";
import { DefaultInvestmentSize } from "@pages/managed-accounts/sma-list/config";

export const SMAListView = (): JSX.Element => {
  const dispatch = useDispatch();
  const smaListFilters = useSelector((state: RootState) => state.smaList);;
  const [displayFeeType, setDisplayFeeType] = useState(
    FeesDisplayStyle.Percentage,
);
  const [showTableDisclaimer, setShowTableDisclaimer] =
    useState<boolean>(false);
  const [investmentSize, setInvestmentSize] = useState(DefaultInvestmentSize);
  const [selectedCalculatedInvestments, setSelectedCalculatedInvestments] =
    useState<CalculatedInvestmentDTO[]>([]);

  const handleSelectedCalculatedInvestments = (
    selectedInvestments: CalculatedInvestmentDTO[],
  ) => {
    setSelectedCalculatedInvestments(
      selectedInvestments.sort((i1, i2) => {
        if (i1.order > i2.order) {
          return 1;
        }

        if (i1.order < i2.order) {
          return -1;
        }

        return 0;
      }),
    );
  };
  const handleFeeDisplayType = (feeDisplayType: FeesDisplayStyle) => {
    setDisplayFeeType(feeDisplayType);
  };
  const handleInvestmentSize = (investmentSize: number) => {
    setInvestmentSize(investmentSize);
  };

  useEffectOnce(() => {
    getInvestmentFiltersPlatforms().then((response) => {
      const newPlatforms = response?.data;
      const isDifferencePlatforms = !isEqual(
        smaListFilters.filters.platforms,
        newPlatforms,
      );

      if (isDifferencePlatforms) {
        const initFilterData = {
          platforms: newPlatforms ?? [],
          favourites: SMAListFavouriteFilter.All,
          currentlySelected: SMAListCurrentlySelectedFilter.All,
          minimumInvestmentSize: SMAListMinimumInvestmentSizeFilter.All,
        };
        dispatch(updateFilters(initFilterData));
      }
    });
  });

  return (
    <SHContainer sx={{ px: { xs: "16px", lg2: 0 } }}>
      <TopBarContainer>
        <TopBar
          title={"Managed account analysis"}
          tools={
            <>
              <ExportMAListExcelButton
                mode={displayFeeType}
                smaListExcelDtoPayload={{
                  selectedInvestments: selectedCalculatedInvestments,
                  investmentSize: investmentSize,
                }}
              />
            </>
          }
        />
      </TopBarContainer>
      <SHStack spacing={2}>
        <SMAListVirtualized
          onSelectedInvestments={handleSelectedCalculatedInvestments}
          onTableViewMode={handleFeeDisplayType}
          onSetInvestmentSize={handleInvestmentSize}
        />
        <SHTypography variant="body4" fontSize={"10px"}>
          By using this table, you confirm that you have read and accepted the{" "}
          <DisclaimerAssumptionsButton
            onClick={() => setShowTableDisclaimer(true)}
          />
        </SHTypography>

        {showTableDisclaimer && (
          <SMATableDisclaimerDialog
            onClose={() => setShowTableDisclaimer(false)}
          />
        )}
      </SHStack>
    </SHContainer>
  );
};
