import { SHBox, SHHtmlBlock } from "@components/design-systems";
import { useTheme } from "@mui/material";
export interface FeatureTextProps {
  content?: string;
}
export const FeatureViewLongText = ({ content }: FeatureTextProps) => {
  const { palette } = useTheme();
  return (
    <SHBox
      sx={{
        backgroundColor: "rgba(197, 221, 253, 0.1)",
        border: "1px solid",
        borderColor: palette.secondary[100],
        padding: "10px 12px 10px 12px",
        mb: "20px",
      }}
    >
      <SHHtmlBlock variant="body4" content={content} color={palette.text.fourth}/>
    </SHBox>
  );
};
