import { SHStack } from "@components/design-systems";
import SHSkeleton from "@components/design-systems/sh-skeleton";
import { useTheme } from "@mui/system";

export const ViewFeatureSkeleton = ({
  delay = false,
}: {
  delay?: boolean;
  initialState?: boolean;
}) => {
  const { palette } = useTheme();

  return (
    <SHStack direction={"row"}>
      <SHStack
        width={"305px"}
        sx={{
          borderRight: 0,
          borderColor: palette.common.black,
        }}
      >
        <SHStack sx={{ mt: 3 }} spacing={1.7}>
          <SHSkeleton height={60} width={"100%"} delay={delay} />
          <SHSkeleton height={60} width={"100%"} delay={delay} />
          <SHSkeleton height={60} width={"100%"} delay={delay} />
          <SHSkeleton height={60} width={"100%"} delay={delay} />
        </SHStack>
      </SHStack>
      <SHStack
        flexGrow={1}
        width={"1px"}
        paddingLeft={"25px"}
        paddingTop={"25px"}
        paddingBottom={"25px"}
      >
        <SHStack spacing={3}>
          <SHSkeleton height={60} width={"100%"} delay={delay} />

          <SHStack spacing={1}>
            <SHSkeleton height={30} width={"100%"} delay={delay} />
            <SHSkeleton height={20} width={"100%"} delay={delay} />
            <SHSkeleton height={20} width={"100%"} delay={delay} />
          </SHStack>

          <SHStack spacing={1}>
            <SHSkeleton height={30} width={"100%"} delay={delay} />
            <SHSkeleton height={20} width={"100%"} delay={delay} />
            <SHSkeleton height={20} width={"100%"} delay={delay} />
          </SHStack>
        </SHStack>
      </SHStack>
    </SHStack>
  );
};
