import { useInvestmentProduct } from "@hooks/useInvestmentProduct";
import { useUserPermissions } from "@hooks/userUserPermission";
import { RootState } from "@redux/store";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { UpgradeSubscriptionView } from "../components/common";
import { ViewFeatureModel } from "../components/view-feature-model";
import { ViewFeatureSkeleton } from "@pages/suppliers/_id/_products/investment-product/_id/view/components/view-feature/components/view-feature-skeleton";

export const ViewModelsTab = () => {
  const { isAdviserTrialGroup } = useUserPermissions();
  const {
    investmentProductModelsData,
    investmentProductUI: { isModelsDataLoading },
  } = useSelector((state: RootState) => state.investmentProduct);
  const { investmentProductId } = useParams();
  const { loadInvestmentProductModels } = useInvestmentProduct();

  useEffect(() => {
    if (isAdviserTrialGroup) return;

    loadInvestmentProductModels(investmentProductId ?? "new", "view");
    // eslint-disable-next-line
  }, [investmentProductId]);

  const isLoadingData = useMemo(() => {
    return !investmentProductModelsData ? isModelsDataLoading : false;
  }, [investmentProductModelsData, isModelsDataLoading]);

  return isAdviserTrialGroup ? (
    <UpgradeSubscriptionView />
  ) : isLoadingData ? (
    <ViewFeatureSkeleton />
  ) : (
    <ViewFeatureModel
      investmentProductModelsData={investmentProductModelsData}
    />
  );
};
