import { GrayDeleteButton } from "@components/buttons/gray-delete";
import { InformationButton } from "@components/buttons/information";
import { PlusButton } from "@components/buttons/plus";
import { RollBackButton } from "@components/buttons/rollback";
import {
  SHAvatar,
  SHBox,
  SHButton,
  SHDataGrid,
  SHDataGridRef,
  SHImage,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { DefaultDataState } from "@components/design-systems/sh-data-grid/constant";
import { StatusBadge } from "@components/status-badge";
import { EditSVG } from "@components/svgs";
import { APIExtRoutes, PageRoutes } from "@constants";
import { DateTimeFormat } from "@constants/format";
import { useRateCard } from "@hooks/useRateCard";
import { TopBar, TopBarContainer } from "@layouts/top-bar";
import {
  RateCardStatus,
  ReadRateCardDTO,
} from "@models/rate-card/entities/rateCard";
import { useTheme } from "@mui/material";
import {
  RateCardFilterOptions,
  RateCardFilterYesNoOptions,
} from "@pages/platform-analysis/constant";
import { TableState, createColumnHelper } from "@tanstack/react-table";
import { nameOfFactory } from "@utils";
import { format } from "date-fns";
import { useMemo, useRef, useState } from "react";
import { generatePath, useNavigate } from "react-router";
import { TextOverflowEllipsis } from "../components/text-overflow-ellipsis";

const nameOf = nameOfFactory<ReadRateCardDTO>();
const columnHelper = createColumnHelper<ReadRateCardDTO>();
const pickColumns: (keyof ReadRateCardDTO)[] = [
  "id",
  "name",
  "productName",
  "productAvatarUrl",
  "ownerName",
  "ownerAvatarUrl",
  "lastModifiedDate",
  "isAvailableForAnalysis",
  "isShared",
  "sharedValue",
  "status",
  "notes",
];

export const RateCardsListView = () => {
  const navigate = useNavigate();
  const { palette } = useTheme();
  const ref = useRef<SHDataGridRef | null>(null);
  const fontSize = "13px";
  const [gridState, setGridState] = useState<Partial<TableState>>({
    ...DefaultDataState,
    sorting: [{ id: nameOf("lastModifiedDate"), desc: true }],
    columnFilters: [
      {
        id: nameOf("status"),
        value: [RateCardStatus.Active],
      },
    ],
  });

  const [changingStatusRateCateIds, setChangingStatusRateCardIds] = useState<
    string[]
  >([]);
  const { updateRateCardStatus } = useRateCard();

  const handleOnChangeStatus = async (
    rateCardId: string,
    status: RateCardStatus,
  ) => {
    setChangingStatusRateCardIds((ids) => [...ids, rateCardId]);
    await updateRateCardStatus(rateCardId, status);
    ref.current?.refreshOdata();
    setChangingStatusRateCardIds((ids) =>
      ids.filter((id) => id !== rateCardId),
    );
  };

  const columns = useMemo(
    () => {
      const cols = [
        columnHelper.accessor((row) => row, {
          id: nameOf("name"),
          header: "Name of rate card",
          cell: (props) => {
            return (
              <SHStack direction={"row"}>
                <TextOverflowEllipsis
                  fontWeight="bold"
                  value={props.cell.getValue().name ?? ""}
                />
                <SHBox
                  sx={{
                    display: "inline-flex",
                    alignItems: "flex-end",
                  }}
                >
                  {props.cell.getValue().notes && (
                    <InformationButton content={props.cell.getValue().notes} />
                  )}
                </SHBox>
              </SHStack>
            );
          },
          enableColumnFilter: false,
          meta: {
            sx: {
              width: "20%",
              minWidth: "250px",
              padding: "0 16px !important",
            },
          },
        }),
        columnHelper.accessor((row) => row, {
          header: "Platform",
          id: nameOf("productId"),
          cell: (props) => {
            return (
              <SHStack direction={"row"} alignItems={"center"}>
                <SHImage
                  width={"24px"}
                  height={"24px"}
                  style={{ objectFit: "contain" }}
                  src={props.cell.getValue().productAvatarUrl}
                />
                <TextOverflowEllipsis
                  value={props.cell.getValue().productName ?? ""}
                  fontWeight="bold"
                  sx={{
                    marginLeft: "5px",
                    minWidth: "100%",
                  }}
                />
              </SHStack>
            );
          },
          enableColumnFilter: false,
          meta: {
            sx: {
              width: "12%",
              padding: "0 16px !important",
            },
          },
        }),
        columnHelper.accessor((row) => row, {
          header: "Owner",
          id: nameOf("ownerName"),
          cell: (props) => {
            return (
              <SHStack direction={"row"} alignItems={"center"}>
                <SHAvatar
                  width={"24px"}
                  height={"24px"}
                  src={props.cell.getValue().ownerAvatarUrl}
                />
                <TextOverflowEllipsis
                  value={props.cell.getValue().ownerName ?? ""}
                  sx={{
                    marginLeft: "5px",
                    minWidth: "100%",
                  }}
                />
              </SHStack>
            );
          },
          enableColumnFilter: false,
          meta: {
            sx: {
              width: "12%",
              padding: "0 16px !important",
            },
          },
        }),
        columnHelper.accessor("lastModifiedDate", {
          header: "Last edit",
          cell: (props) => {
            const date = props.cell.getValue();
            return date ? format(new Date(date), DateTimeFormat) : "";
          },
          enableColumnFilter: false,
          meta: {
            sx: {
              width: "13%",
              minWidth: { xs: "130px" },
              padding: "0 0 0 25px !important",
            },
          },
        }),
        columnHelper.accessor("isAvailableForAnalysis", {
          header: "Available for my analysis",
          cell: (props) => (
            <SHTypography
              fontSize={fontSize}
              lineHeight={"120%"}
              minWidth={"100px"}
            >
              {props.cell.getValue() ? "Yes" : "No"}
            </SHTypography>
          ),
          meta: {
            filterData: RateCardFilterYesNoOptions,
            sx: {
              width: { xs: "100px", md: "12%" },
              minWidth: "100px",
              padding: "0 5px !important",
            },
          },
        }),
        columnHelper.accessor("sharedValue", {
          header: "Shared",
          cell: (props) => (
            <SHTypography fontSize={fontSize} lineHeight={"120%"}>
              {props.cell.getValue()}
            </SHTypography>
          ),
          meta: {
            filterData: RateCardFilterYesNoOptions,
            sx: {
              width: "5%",
              maxWidth: "120px",
              padding: "0 16px !important",
            },
          },
        }),
        columnHelper.accessor("status", {
          header: "Status",
          cell: (props) => (
            <StatusBadge
              style={{ width: "88px", fontSize: fontSize }}
              status={props.cell.getValue() as RateCardStatus}
            />
          ),
          meta: {
            filterData: RateCardFilterOptions,
            sx: {
              width: "10%",
              maxWidth: "120px",
              padding: "0 16px !important",
            },
          },
        }),
        columnHelper.accessor((row) => row, {
          id: "actionCol",
          header: "Action",
          enableColumnFilter: false,
          enableSorting: false,
          cell: (props) => (
            <SHStack
              direction="row"
              justifyContent={"space-between"}
              spacing={0.5}
            >
              <SHButton
                sx={{ width: "70%", fontSize: fontSize }}
                startIcon={<EditSVG color={palette.common.white} />}
                variant="contained"
                disabled={false}
                onClick={() => {
                  navigate(
                    generatePath(PageRoutes.platformAnalysisRateCard, {
                      rateCardId: props.cell.getValue().id,
                    }),
                  );
                }}
              >
                Edit
              </SHButton>
              {props.cell.getValue().status === RateCardStatus.Archived ? (
                <RollBackButton
                  disable={changingStatusRateCateIds.includes(
                    props.cell.getValue().id ?? "",
                  )}
                  isLoading={changingStatusRateCateIds.includes(
                    props.cell.getValue().id ?? "",
                  )}
                  onClick={() => {
                    handleOnChangeStatus(
                      props.cell.getValue().id ?? "",
                      RateCardStatus.Active,
                    );
                  }}
                />
              ) : (
                <GrayDeleteButton
                  disable={changingStatusRateCateIds.includes(
                    props.cell.getValue().id ?? "",
                  )}
                  isLoading={changingStatusRateCateIds.includes(
                    props.cell.getValue().id ?? "",
                  )}
                  onClick={() => {
                    handleOnChangeStatus(
                      props.cell.getValue().id ?? "",
                      RateCardStatus.Archived,
                    );
                  }}
                />
              )}
            </SHStack>
          ),
          meta: {
            sx: {
              width: "10%",
            },
          },
        }),
      ];
      return cols;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      <TopBarContainer>
        <TopBar
          title={"Platform analysis"}
          tools={
            <>
              <PlusButton
                data-testid="new-supplier-button"
                variant="contained"
                size="extraMedium"
                onClick={() =>
                  navigate(
                    generatePath(PageRoutes.platformAnalysisRateCard, {
                      rateCardId: "new",
                    }),
                  )
                }
              >
                New rate card
              </PlusButton>
            </>
          }
        />
      </TopBarContainer>
      <SHBox sx={{ marginTop: "25px" }}>
        <SHDataGrid
          ref={ref}
          odata={{
            url: APIExtRoutes.odataRateCates,
            pickColumns: pickColumns,
            globalFilterColumns: [
              nameOf("name"),
              nameOf("ownerName"),
              nameOf("productName"),
            ],
          }}
          emptyMessage={"No rate card."}
          columns={columns as any}
          state={gridState}
          onChangeState={(state) => setGridState(state)}
          searchBoxProps={{
            placeholder: "Search name, platforms and owners",
          }}
        />
      </SHBox>
    </>
  );
};
