import {
  SHBox,
  SHHtmlBlock,
  SHPopper,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { InfoSVG, TooltipAllowSVG } from "@components/svgs";
import { IconButton, PopperPlacementType, useTheme } from "@mui/material";
import { hexToRGBA } from "@utils";
import { useEffect, useRef, useState } from "react";
import { CloseButton } from "../close";
import { configTooltipStyle } from "@components/buttons/information/config";

export interface InformationButtonProps {
  margin?: string | number;
  title?: string | React.ReactNode;
  content?: string | React.ReactNode;
  placement?: PopperPlacementType;
  [key: string]: any;
}
export const InformationButton = ({
  margin,
  title,
  content,
  placement = "top-start",
  ...others
}: InformationButtonProps) => {
  const { palette, shadows } = useTheme();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const tooltipConfig = configTooltipStyle[placement];

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  useEffect(() => {
    if (!open) return;
    const handleClickOutside = (event: any) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, buttonRef, open]);

  return (
    <>
      <IconButton
        {...others}
        ref={buttonRef}
        onClick={handleClick}
        disableRipple
        sx={{
          width: "16px",
          height: "16px",
          margin: margin ?? "2px 0px 2px 4px !important",
          p: 0,
          borderStyle: "solid",
          borderWidth: "1px",
          backgroundColor: "transparent",
          borderColor: palette.text.primary,
          "&:hover": {
            borderColor: palette.primary.main,
            boxShadow: "none",
            "&>svg>path": {
              fill: palette.primary.main,
            },
          },
          "&:active": {
            backgroundColor: palette.primary.main,
            borderColor: palette.primary.dark,
            "&>svg>path": {
              fill: palette.common.white,
            },
          },
          "&:disabled": {
            border: `1px solid ${palette.text.disabled}`,

            "&>svg>path": {
              fill: palette.text.disabled,
            },
          },
        }}
      >
        <InfoSVG />
      </IconButton>
      <SHPopper
        anchorEl={anchorEl}
        open={open}
        onClick={(e) => e.stopPropagation()}
        placement={placement}
        popperOptions={{ modifiers: [{ name: "flip", enabled: false }] }}
        sx={{ zIndex: 1400 }}
      >
        <SHBox pb={1}>
          <SHStack
            ref={wrapperRef}
            sx={{
              p: "15px 25px 10px 15px",
              maxWidth: "280px",
              minHeight: "20px",
              bgcolor: hexToRGBA(palette.common.white, 0.85),
              border: "1px solid #E3E3E3",
              backdropFilter: "blur(2px)",
              borderRadius: "3px",
              boxShadow: shadows[1],
              position: "relative",
            }}
          >
            {title && typeof title === "string" ? (
              <SHTypography variant="body1" zIndex="1">
                {title}
              </SHTypography>
            ) : (
              title
            )}
            {content && typeof content === "string" ? (
              <SHHtmlBlock
                variant="body3"
                textAlign={"left"}
                zIndex={1}
                color={palette.text.disabled}
                content={content}
              />
            ) : (
              <SHBox
                sx={{
                  fontSize: "13px",
                  fontWeight: 500,
                  color: palette.text.disabled,
                }}
              >
                {content}
              </SHBox>
            )}
            <SHBox sx={{ position: "absolute", top: "5px", right: "10px" }}>
              <CloseButton onClick={handleClick} />
            </SHBox>
            <SHBox sx={tooltipConfig?.sx}>
              <TooltipAllowSVG transform={tooltipConfig?.transform} />
            </SHBox>
          </SHStack>
        </SHBox>
      </SHPopper>
    </>
  );
};
