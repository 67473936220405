import { useSelector } from "react-redux";
import { ViewFeature } from "../components/view-feature";
import { RootState } from "@redux/store";
import { useEffect, useMemo } from "react";
import { useInvestmentProduct } from "@hooks/useInvestmentProduct";
import { useParams } from "react-router";
import { useUserPermissions } from "@hooks/userUserPermission";
import { UpgradeSubscriptionView } from "../components/common";
import { ViewFeatureSkeleton } from "@pages/suppliers/_id/_products/investment-product/_id/view/components/view-feature/components/view-feature-skeleton";

export const ViewSeriesTab = () => {
  const {
    investmentProductSeriesData,
    investmentProductUI: { isSeriesDataLoading },
  } = useSelector((state: RootState) => state.investmentProduct);
  const { isAdviserTrialGroup } = useUserPermissions();
  const { investmentProductId } = useParams();
  const { loadInvestmentProductSeries } = useInvestmentProduct();

  useEffect(() => {
    if (isAdviserTrialGroup) return;

    loadInvestmentProductSeries(investmentProductId ?? "new", "view");
    // eslint-disable-next-line
  }, [investmentProductId]);

  const isLoadingData = useMemo(() => {
    return !investmentProductSeriesData ? isSeriesDataLoading : false;
  }, [investmentProductSeriesData, isSeriesDataLoading]);

  return isAdviserTrialGroup ? (
    <UpgradeSubscriptionView />
  ) : isLoadingData ? (
    <ViewFeatureSkeleton />
  ) : (
    <ViewFeature investmentProductSeriesData={investmentProductSeriesData} />
  );
};
